<template>
  <div class="custom-field-query-builder">
    <div
      v-for="condition in query.conditions"
      :key="condition.value"
      class="condition mb-2 d-flex"
    >
      <calm-select
        v-model="condition.field_ident"
        :options="identsForSelect"
        :placeholder="$t('lookups.custom_fields_query_builder.id')"
      />
      <select v-model="condition.operator" class="form-control">
        <option
          v-for="operator in ['eq', 'contains', 'not_eq', 'is_set', 'is_not_set']"
          :key="operator"
          :value="operator"
        >
          {{ $t(`lookups.custom_fields_query_builder.operators.${operator}`) }}
        </option>
      </select>
      <calm-select
        v-model="condition.value"
        :options="idents[condition.field_ident] || []"
        :placeholder="$t('lookups.custom_fields_query_builder.value')"
        :class="{'opacity-0': condition.operator.includes('set')}"
      />
      <div class="btn btn-light" @click="removeCondition(condition)">
        <i class="fa fa-times" />
      </div>
    </div>

    <div class="d-flex">
      <button
        type="button"
        class="btn btn-dark"
        @click="addCondition"
      >
        {{ $t('lookups.custom_fields_query_builder.add_filter') }}
      </button>
      <select
        v-if="query.conditions.length > 1"
        v-model="query.context"
        class="form-control w-auto ms-2"
      >
        <option value="and">
          {{ $t('lookups.custom_fields_query_builder.contexts.and') }}
        </option>
        <option value="or">
          {{ $t('lookups.custom_fields_query_builder.contexts.or') }}
        </option>
      </select>
      <a class="btn" @click.prevent="openHelpModal">
        <i class="fa fa-question-circle" />
      </a>
    </div>

    <input
      :value="queryJson"
      type="hidden"
      :name="name"
    >
  </div>
</template>

<script>
import CalmSelect from 'components/common/CalmSelect'

export default {
  components: { CalmSelect },
  props: {
    initialQuery: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      required: true
    },
    idents: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { query: null }
  },
  created() {
    this.query = this.initialQuery || {
      conditions: [],
      context: 'and'
    }
  },
  computed: {
    queryJson() {
      return JSON.stringify(this.query)
    },
    identsForSelect() {
      const result = []
      Object.entries(this.idents || []).forEach(([id, choices]) => {
        if (choices && choices.length > 0) result.push(id)
      })
      return result
    }
  },
  methods: {
    addCondition() {
      this.query.conditions.push({ field_ident: '', operator: 'eq', value: '' })
    },
    removeCondition(condition) {
      this.query.conditions = this.query.conditions.filter(
        (c) => c.field_ident != condition.field_ident
      )
    },
    openHelpModal() {
      alertBootstrap(
        this.$t('lookups.custom_fields_query_builder.info'),
        'Custom Fields',
        null,
        'primary'
      )
    }
  }
}
</script>
