export default function styleConfirmationPopupWithBootstrap() {
  $('[data-confirm][data-confirm-type!="no-modal"]:not([data-initialized=true])').click(function(ev) {
    const $link = $(this)
    const href = $link.attr('href')
    const reqMethod = $link.data('method')
    const remote = $link.data('remote')
    let linkMethodStr = reqMethod ? ` data-method="${reqMethod}"` : ''
    const linkClass = reqMethod == 'delete' ? 'btn-danger' : 'btn-primary'
    linkMethodStr += remote ? ` data-remote="${remote}"` : ''
    $('#dataConfirmModal').remove()
    $('body').append(`<div id="dataConfirmModal" class="modal" role="dialog" aria-labelledby="dataConfirmLabel" aria-hidden="true"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button><h3 id="dataConfirmLabel">${gon.confirmation_modal_title}</h3></div><div class="modal-body"></div><div class="modal-footer"><button class="btn" data-dismiss="modal" aria-hidden="true">${gon.action_no}</button><a class="btn ${linkClass}" id="dataConfirmOK"${linkMethodStr} data-disable="true">${gon.action_yes}</a></div></div></div></div>`)
    const text = $(this).attr('data-confirm')
    let html = text
    if (text.indexOf('<br>') > -1) {
      const separateLines = text.split('<br>')
      html = ''
      for (let i = 0; i < separateLines.length; i++) {
        const line = separateLines[i]
        html += `<p>${line}</p>`
      }
    }

    $('#dataConfirmModal').find('.modal-body').html(html)

    // Confirm link
    if ($(this).is('a')) {
      $('#dataConfirmOK').attr('href', href)
      if ($(this).data('confirm_v_remote_modal') == true) {
        // refs #5243 AT Approves is a v-remote-modal and needed a confirmation modal too,
        // so the Yes btn must be initialized as a v-remote-modal as well
        $('#dataConfirmOK').attr('v-remote-modal', 'v-remote-modal')
        window.dispatchEvent(new Event('initAllJsAfterContentLoaded'))
      }
    } // Confirm form submit
    else {
      $btn = $(this)
      $('#dataConfirmOK').attr('href', '#')
      $('#dataConfirmOK').click((e) => {
        $btn.closest('form').submit()
        $('#dataConfirmModal').modal('hide')
        e.preventDefault()
        e.stopPropagation()
      })
    }

    $('#dataConfirmModal').modal({ show: true })

    return false
  })

  $('a[data-confirm][data-confirm-type!="no-modal"]').each(function(ev) {
    $(this).attr('data-initialized', true)
  })
}
