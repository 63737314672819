<template>
  <DataTable
    v-bind="tableBindings"
    sort-field="sent_at"
    :sort-order="-1"
    responsive-layout="scroll"
    v-on="tableListeners"
  >
    <Column field="transaction_date">
      <template #body="slotProps">
        {{ slotProps.data.sent_at.toLocaleDateString(locale, {month: 'long', year: 'numeric'}) }}
      </template>
    </Column>

    <Column :expander="true" body-class="expander" />

    <!-- Content -->
    <template #expansion="slotProps">
      <div class="full-page-print">
        <div
          class="btn btn-print rounded-circle pi pi-print fs-2 float-end d-print-none"
          @click="printReport()"
        />
        <SegmentsRenderer :segments="slotProps.data.segments" action="show" />
      </div>
    </template>
  </DataTable>
</template>

<script>
import DatatableExpandableMixin from 'mvip/helpers/datatable-expandable-mixin'
import SegmentsRenderer from 'components/letters/SegmentsRenderer'

export default {
  components: { SegmentsRenderer },
  mixins: [DatatableExpandableMixin],
  methods: {
    printReport() {
      window.print()
    }
  }
}
</script>

<style lang='scss' scoped>
  :deep(.p-datatable-thead) {
    display: none;
  }
  .btn-print {
    padding: .75rem;
    background-color: var(--bs-gray-200);
    line-height: 1;
  }
</style>
