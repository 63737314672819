export default function() {
  // Listen to some message sent by child iframe (used by VIS)
  window.addEventListener('message', (message) => {
    if (message.data.event == 'height') {
      const event = message.data
      const selector = event.iframeName ? `iframe[name="${event.iframeName}"` : 'iframe'
      const iframe = document.querySelector(selector)
      iframe.style.height = `${event.value}px`
    }

    // Some links does not work inside iframe (for external SSO providers)
    // the redirectuon should happen in the main window
    if (message.data.event == 'redirect') {
      Turbo.visit(message.data.href)
    }
  })
}
