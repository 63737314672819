<template>
  <div class="spinner-container">
    <div class="spinner">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<style lang='scss' scoped>
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
  }
</style>
