<template>
  <div class="active-application">
    <MessageForm ref="form" :messages="messages" @msg-created="onNewMessage($event)" />

    <div class="card mb-4 full-width-sm">
      <div
        class="card-header d-flex align-items-center"
        :class="active ? 'text-bg-primary' : 'inactive'"
      >
        <div class="title me-auto d-flex align-items-center">
          <span class="course-summary ff-heading fw-semibold fs-11-rem">
            <span>{{ eventDisplay }}</span>
            <span
              v-if="app.participation != 'new_student'"
              class="participation"
            >{{ participationDisplay }}</span>
          </span>
          <AppStateDisplay :app="app" />
        </div>
        <div class="d-none d-md-block">
          <!-- Contact Us -->
          <a
            class="btn"
            :class="active ? 'btn-outline-light' : 'btn-outline-dark'"
            @click="newMessage"
          >
            {{ $t('comm.transmissions.index.contact') }}
          </a>
          <!-- Cancel App -->
          <a
            v-if="cancellable"
            class="btn btn-secondary ms-3"
            :href="$routes.mvip_mvip_cancel_application_path({ id: app.id })"
          >
            {{ $t('comm.lookups.action_radio_buttons.cancel') }}
          </a>
        </div>
      </div>
      <div class="card-body">
        <p v-show="app.event_type != 'LongTermService'" class="card-text date">
          <i class="pi pi-calendar me-2" />
          <span>
            {{ app.start_date.toLocaleDateString(locale, {day: 'numeric', month: 'short'}) }}
          </span>
          <span> - </span>
          <span>
            {{ app.end_date.toLocaleDateString(
              locale, {day: 'numeric', month: 'short', year: 'numeric' }) }}
          </span>
        </p>
        <p class="card-text">
          <i class="pi pi-map-marker me-2" />
          {{ app.venue }}
        </p>
        <div class="actions d-flex">
          <!-- See Messages -->
          <div
            class="btn me-auto align-items-baseline"
            :class="btnMessageClass"
            @click="loadMessages"
          >
            {{ $t('comm.course_applications.actions.see_messages') }}
            <span class="ms-1 fs-08-em">
              <i v-if="showMessages" class="pi pi-minus" />
              <i v-else class="pi pi-plus" />
            </span>
          </div>
          <!-- Contact Us -->
          <a
            class="btn btn-sm ms-2 d-md-none"
            :class="active ? 'btn-outline-secondary' : 'btn-outline-dark'"
            @click="newMessage"
          >
            {{ $t('comm.transmissions.index.contact') }}
          </a>
          <!-- Cancel App -->
          <a
            v-if="cancellable"
            class="btn btn-sm btn-secondary ms-2 d-md-none"
            :href="$routes.mvip_mvip_cancel_application_path({ id: app.id })"
          >
            {{ $t('comm.lookups.action_radio_buttons.cancel') }}
          </a>
        </div>
      </div>
      <div v-if="showMessages" class="messages-container card-body border-top p-0">
        <CalmSpinner v-show="!messagesLoaded" class="my-3" />
        <MessagesList v-show="messagesLoaded" ref="msgList" :messages="messages" />
      </div>
    </div>
  </div>
</template>

<script>
import { snakeCase } from 'helpers/string_helper'
import { get, handleAjaxError } from 'helpers/core/ajax-utils'
import messagesTransformMixin from 'mvip/pages/mvip_transmissions/messages-transform-mixin'

import MessageForm from 'mvip/pages/mvip_transmissions/MessageForm.vue'
import MessagesList from 'mvip/pages/mvip_transmissions/MessagesList.vue'
import AppStateDisplay from './AppStateDisplay.vue'

export default {
  components: { AppStateDisplay, MessageForm, MessagesList },
  mixins: [messagesTransformMixin],
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showMessages: false,
      messagesLoaded: false,
      messages: []
    }
  },
  computed: {
    participationDisplay() {
      const key = this.app.participation.includes('server') ? 'service' : 'sitting'
      return this.$t(`comm.course_applications.attrs.participation_values.${key}`)
    },
    eventDisplay() {
      return this.$t(`activerecord.models.${snakeCase(this.app.event_type)}.one`)
    },
    cancellable() {
      const cancelledStatus = ['Cancelled', 'Discontinued', 'NewNoVacancy', 'NoVacancy', 'Refused']
      return this.app.start_date > new Date() && !cancelledStatus.includes(this.app.conf_state)
    },
    active() {
      return ![
        'Cancelled',
        'Discontinued',
        'NewNoVacancy',
        'NoVacancy',
        'Refused'
      ].includes(this.app.conf_state)
    },
    btnMessageClass() {
      let result = this.screen.isSM ? 'btn-sm ' : ''
      if (!this.active) result += 'btn-outline-dark'
      else result += this.screen.isSM ? 'btn-outline-primary' : 'btn-outline-secondary'
      return result
    }
  },
  methods: {
    newMessage() {
      this.$refs.form.open({ course_application_id: this.app.id }, { displaySave: false })
    },
    loadMessages() {
      this.showMessages = !this.showMessages
      if (!this.messagesLoaded) {
        get(this.$routes.mvip_student_messages_path({ course_application_id: this.app.id }))
          .then((result) => {
            this.messagesLoaded = true
            this.messages = this.transformMessages(result.data)
          }).catch((error) => {
            handleAjaxError(error, this)
          })
      }
    },
    onNewMessage(msg) {
      if (this.$refs.msgList) this.$refs.msgList.open(msg)
    }
  }
}
</script>

<style lang='scss'>
  .active-application {
    .card-header.inactive {
      background-color: var(--bs-blue-200) !important;
    }
    .participation {
      &:before {
        content: " - ";
      }
    }
    .messages-container {
      .p-datatable {
        margin-bottom: 0;
        .p-datatable-wrapper {
          border-top-right-radius: 0 !important;
          border-top-left-radius: 0 !important;
          .p-datatable-thead {
            display: none;
          }
        }
      }
    }
    @media (max-width: 768px) {
      .title {
        width: 100%;
        .course-summary {
          margin-right: auto;
        }
      }
      .date {
        margin-bottom: .5rem;
      }
      .p-datatable-wrapper {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
</style>
