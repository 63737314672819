<template>
  <div class="messages-list">
    <MessageForm ref="form" :messages="messages" @msg-created="open($event)" />

    <!-- Table -->
    <DataTable
      v-bind="tableBindings"
      :value="messages"
      :row-class="rowClass"
      responsive-layout="scroll"
      :paginator="messages.length > 10"
      :rows="10"
      sort-field="display_sent_at"
      :sort-order="-1"
      v-on="tableListeners"
    >
      <!-- Subject -->
      <Column field="subject" :header="$t('comm.transmissions.index.subject')">
        <template #body="slotProps">
          <div class="title">
            <i v-if="slotProps.data.haveFormToComplete" class="pi pi-exclamation-circle me-2" />
            <span class="d-inline-flex align-items-center">
              <i
                v-if="['form_completed', 'applicant_sent', 'applicant_draft']
                  .includes(slotProps.data.status)"
                class="pi pi-send me-2"
              />
              <CroppedText :text="getMessageTitle(slotProps.data)" :length="50" />
            </span>
            <i v-if="slotProps.data.attachments.length > 0" class="pi pi-paperclip ms-2 fs-6" />
          </div>
          <div v-if="screen.isSM" class="subtitle">
            {{ slotProps.data.display_sent_at.toLocaleDateString(
              locale, {day: 'numeric', month: 'short', year: '2-digit' }) }}
          </div>
        </template>
      </Column>

      <!-- Sent at -->
      <Column
        v-if="screen.upMD"
        field="display_sent_at"
        :header="$t('comm.transmissions.index.received_at')"
        :sortable="true"
      >
        <template #body="slotProps">
          {{ slotProps.data.display_sent_at.toLocaleDateString(
            locale, {day: 'numeric', month: 'short', year: '2-digit' }) }}
        </template>
      </Column>

      <!-- Content -->
      <template #expansion="slotProps">
        <div
          v-if="Object.values(slotProps.data.actions || {}).some(a => a)"
          class="actions border-bottom pb-3 mb-3"
        >
          <button
            v-if="slotProps.data.actions.answer"
            class="btn btn-outline-secondary"
            @click="openReplyFormLink()"
          >
            {{ $t('comm.transmissions.index.answer_form') }}
          </button>
          <button
            v-if="slotProps.data.actions.reply"
            class="btn btn-outline-primary"
            @click="newReply(slotProps.data)"
          >
            <i class="pi pi-reply me-2" />
            {{ $t('comm.lookups.actions.reply') }}
          </button>
        </div>
        <div v-if="slotProps.data.attachments.length > 0" class="attachments-container mb-3">
          <a
            v-for="attachment in slotProps.data.attachments"
            :key="attachment.id"
            class="btn btn-sm btn-outline-dark me-2"
            target="_blank"
            :href="$routes.mvip_download_attachment_path(attachment.id)"
          >
            {{ attachment.name }}
          </a>
        </div>
        <div ref="msgContent" class="msg-content">
          <SegmentsRenderer :segments="slotProps.data.segments" action="show" />
        </div>
      </template>

      <!-- Empty -->
      <template #empty>
        <div class="text-center p-4">
          {{ $t('comm.transmissions.index.no_messages') }}
        </div>
      </template>

      <Column :expander="true" body-class="expander" />
    </DataTable>
  </div>
</template>

<script>
import DatatableMixin from 'mvip/helpers/datatable-expandable-mixin'
import SegmentsRenderer from 'components/letters/SegmentsRenderer'
import MessageForm from './MessageForm.vue'

export default {
  components: { SegmentsRenderer, MessageForm },
  mixins: [DatatableMixin],
  props: {
    messages: {
      type: Array,
      required: true
    }
  },
  mounted() {
    // next tick in order to wait for calm select to be initialized
    this.$nextTick(function() {
      if (gon.display_msg_id) {
        const msgToEmphasis = this.messages.find((msg) => msg.id == gon.display_msg_id)
        if (msgToEmphasis) this.expandedRows = { [msgToEmphasis.id]: true }
      }
    })
  },
  methods: {
    rowClass(msg) {
      const classes = []
      if (msg.haveFormToComplete) classes.push('form-not-completed')
      if (this.expandedRows[msg.id]) classes.push('expanded')
      return classes.join(' ')
    },
    open(msg) {
      this.expandedRows = { [msg.id]: true }
    },
    openReplyFormLink() {
      if (this.$refs.msgContent) {
        this.$refs.msgContent
          .querySelector('a[href*="edit?token"]')
          .click()
      }
    },
    newReply(msg) {
      this.$refs.form.open({
        course_application_id: msg.app_id,
        subject: `Re: ${msg.subject}`
      })
    },
    getMessageTitle(msg) {
      if (msg.status == 'note_saved' || msg.delivery_method == 'sms') return msg.template_name // sms have no subject
      if (msg.status.includes('doctor')) {
        return this.$t('lookups.message_status.form_completed_doctor')
      }
      if (msg.status == 'form_completed') return this.$t('comm.transmissions.index.form_reply')
      return msg.subject || this.$t('lookups.transmission_template.custom_message.name')
    }
  }
}
</script>

<style lang='scss' scoped>
  :deep(.form-not-completed .title) {
    font-weight: bold;
    color: var(--bs-secondary) !important;
  }
  :deep(.p-datatable-thead) {
    display: none;
  }
</style>
