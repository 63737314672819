export default class WysiwygUploadPlugin {
  editor

  i18n = {}

  constructor(editor) {
    this.editor = editor
    this.initMenu()
  }

  initMenu() {
    const uploadMenu = []

    const $modals = [$('#add-image-modal'), $('#add-pdf-modal')]
    $modals.forEach(($modal) => {
      if ($modal.length > 0) {
        const that = this
        uploadMenu.push({
          text: $modal.find('.modal-title').text(),
          onclick() {
            that.initAjaxModal($modal)
            $modal.modal('show')
          }
        })
      }
    })

    const $existingFilesLink = $('.browse-files-link')
    if ($existingFilesLink.length > 0) {
      uploadMenu.push({
        text: $existingFilesLink.data('label'),
        onclick() { window.open($existingFilesLink.data('url'), '_blank').focus() }
      })
    }

    if (uploadMenu.length > 0) {
      this.editor.addButton('calm_upload', {
        type: 'menubutton',
        text: gon.upload_label,
        icon: 'upload',
        menu: uploadMenu
      })
    }
  }

  initAjaxModal($modal) {
    const $form = $modal.find('form')

    $form.find('[type=submit]').on('click', () => {
      this.hideErrors($modal)
    })
    $form.off('ajax:complete').on('ajax:complete', (e, xhr) => {
      // some weird stuff happen, the result is not correctly parsed as Json, so parsing ourself
      this.handleAjaxResult($modal, JSON.parse(xhr.responseText))
    })
  }

  handleAjaxResult($modal, data) {
    if (data.success == true) {
      $modal.modal('hide')
      let result = ''
      if ($modal.attr('id') == 'add-image-modal') result = `<br/><img src="${data.url}"/><br/>`
      else result = `<br/><a href="${data.url}"/>${data.url}</a><br/>`
      this.editor.insertContent(result)
      this.resetForm($modal)
    } else {
      this.showErrors($modal, '', data.errors)
    }
  }

  showErrors($modal, statusStr, errorStr) {
    if (!errorStr) return
    $modal.find('.errors').show().html(`Something went wrong: ${statusStr} Error: ${errorStr}`)
  }

  resetForm($modal) {
    $modal.find('input:not([type=submit])').val('')
    $modal.find('textarea').val('')
    this.hideErrors($modal)
  }

  hideErrors($modal) {
    $modal.find('.errors').hide()
  }
}
