<template>
  <div class="course-history">
    <h2 class="d-flex align-items-center mb-3">
      <div class="me-auto">
        {{ $t('comm.course_applications.headings.history') }}
      </div>
      <div class="ff-normal">
        <template v-if="screen.isSM">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle rounded-circle p-2"
              aria-expanded="false"
              data-bs-toggle="dropdown"
              type="button"
            >
              <div class="pi pi-plus" />
            </button>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" @click="addCustomApp">
                  {{ $t('comm.course_applications.actions.add_course') }}
                </a>
              </li>
              <li>
                <a class="dropdown-item" @click="addCustomAppInBatch">
                  {{ $t('comm.course_applications.actions.add_course_in_batch') }}
                </a>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <a class="btn btn-outline-secondary" @click="addCustomAppInBatch">
            <div class="pi pi-plus me-1" />
            {{ $t('comm.course_applications.actions.add_course_in_batch') }}
          </a>
          <a class="btn btn-secondary ms-3" @click="addCustomApp">
            <div class="pi pi-plus me-1" />
            {{ $t('comm.course_applications.actions.add_course') }}
          </a>
        </template>
      </div>
    </h2>

    <Data-Table
      :value="previousApps"
      :paginator="previousApps.length > 10"
      :rows="10"
      data-key="id"
      responsive-layout="scroll"
    >
      <Column v-if="screen.isSM" field="id">
        <template #body="{ data }">
          <span>{{ $root.translateEventType(data.event_type) }}</span>
          <span> - </span>
          <span class="ms-1">
            <template v-if="['Completed', 'Arrived'].includes(data.conf_state)">
              {{ $t('comm.course_applications.attrs.participation_values.' + data.participation) }}
            </template>
            <template v-else>
              {{ $t('lookups.course_app_confirmation_states.shorter_display_name.'
                + data.conf_state) }}
            </template>
          </span>
          <div class="subtitle">
            <template v-if="data.is_batch">
              {{ $t('comm.course_applications.attrs.batch_count_description',
                    { count: data.batch_count }) }}
            </template>
            <template v-else>
              <span v-if="data.start_date" class="text-capitalize">
                {{ data.start_date.toLocaleDateString(locale, {month: 'long', year: "numeric" }) }}
              </span>
              <span v-if="data.venue" class="text-capitalize">
                {{ data.venue.length > 1 ? ' - ' + data.venue : '' }}
              </span>
              <span v-if="data.teacher && data.teacher.name">
                {{ data.teacher.name.length > 1 ? ' - ' + data.teacher.name : '' }}
              </span>
            </template>
          </div>
        </template>
      </Column>

      <Column v-if="screen.upMD" field="dates" :header="$t('comm.course_applications.attrs.dates')">
        <template #body="{ data }">
          <template v-if="data.start_date">
            <span>{{ data.start_date.toLocaleDateString(locale, {year: "numeric" }) }}</span>
            <span class="ms-1">
              {{ data.start_date.toLocaleDateString(locale, {month: 'short' }) }}
            </span>
          </template>
        </template>
      </Column>

      <Column
        v-if="screen.upMD"
        field="event_type"
        :header="$t('comm.course_applications.attrs.event_type')"
      >
        <template #body="{ data }">
          {{ $root.translateEventType(data.event_type) }}
          <span v-show="data.event_type != 'LongTermService'" class="badge text-bg-light ms-1">
            <template v-if="['Completed', 'Arrived'].includes(data.conf_state)">
              {{ $t('comm.course_applications.attrs.participation_values.' + data.participation) }}
            </template>
            <template v-else>
              {{ $t('lookups.course_app_confirmation_states.shorter_display_name.'
                + data.conf_state) }}
            </template>
          </span>
        </template>
      </Column>

      <Column
        v-if="screen.upMD"
        field="infos"
        :header="`${$t('comm.course_applications.attrs.location')} - ${$t('comm.course_applications.attrs.teacher')}`"
      >
        <template #body="{ data }">
          <template v-if="data.is_batch">
            {{ $t('comm.course_applications.attrs.batch_count_description',
                  { count: data.batch_count }) }}
          </template>
          <template v-else>
            <span v-if="data.venue" class="text-capitalize">
              {{ data.venue }}
            </span>
            <span v-if="data.venue && data.teacher && data.teacher.name.length > 1"> - </span>
            <span v-if="data.teacher && data.teacher.name" class="text-capitalize">
              {{ data.teacher.name }}
            </span>
          </template>
        </template>
      </Column>

      <Column header-style="width: 6rem; flex-shrink: 0" body-class="text-end">
        <template #body="{ data }">
          <div class="d-flex">
            <div v-if="data.editable" class="btn btn-sm d-inline" @click="editApp(data)">
              <i class="pi pi-pencil" />
            </div>
            <ConfirmButton
              v-if="data.editable"
              btn-class="btn-sm d-inline"
              type="danger"
              @confirmed="$root.destroy('apps', data)"
            >
              <i class="pi pi-times" />
            </ConfirmButton>
          </div>
        </template>
      </Column>

      <template #empty>
        <div class="text-center">
          {{ $t('datatable.common.no_result') }}
        </div>
      </template>
    </Data-Table>

    <CourseHistoryForm ref="form" />
    <CourseHistoryBatchForm ref="batchForm" />
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import CourseHistoryForm from './CourseHistoryForm.vue'
import CourseHistoryBatchForm from './CourseHistoryBatchForm.vue'

export default {
  components: { DataTable, Column, CourseHistoryForm, CourseHistoryBatchForm },
  computed: {
    previousApps() {
      return this.$root.apps
        .filter((app) => app.is_batch || app.start_date < new Date())
        .sort((a, b) => {
          if (!a.start_date || !b.start_date) return a.start_date ? 1 : -1
          return b.start_date - a.start_date
        })
    }
  },
  methods: {
    addCustomApp() {
      this.$refs.form.editApp({
        editable: true,
        conf_state: 'Completed'
      })
    },
    addCustomAppInBatch() {
      this.$refs.batchForm.editApp({
        editable: true,
        conf_state: 'Completed',
        is_batch: true
      })
    },
    editApp(app) {
      app.is_batch ? this.$refs.batchForm.editApp(app) : this.$refs.form.editApp(app)
    }
  }
}
</script>

<style scoped>
  /* Weird bug in this component DataTable, the table is displayed with a weird scrollbar
     end empty space below */
  :deep(.p-datatable-responsive-scroll > .p-datatable-wrapper) {
    overflow: visible !important;
  }
</style>
