import DanasTable from './DanasTable.vue'
import DanaReportTable from './DanaReportTable.vue'

export default {
  components: { DanasTable, DanaReportTable },
  data: {
    danas: [],
    reports: []
  },
  mounted() {
    this.danas = gon.danas.map((dana) => ({
      ...dana,
      transaction_date: new Date(dana.transaction_date),
      receipt: null // initialize the value so prop gets reactive
    }))
    this.reports = gon.reports.map((msg) => ({
      ...msg,
      sent_at: new Date(msg.sent_at)
    }))
  }
}
