export default class {
  constructor(parent) {
    this.parent = parent
  }

  get $formSelect() {
    return $('#choose-form')
  }

  get $modal() {
    return $('#choose-form-dialog')
  }

  open() {
    if (this.$modal.hasClass('initialized')) {
      this.$formSelect.val('').trigger('change')
      this.$modal.modal('show')
      return
    }

    const url = $('#form-link-builder').data('ajaxUrl')
    $.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        const options = [{ id: '', text: '' }].concat(data.loc_vars)
        this.$formSelect.select2({ data: options })
        this.$modal.modal('show').addClass('initialized')
      },
      error: calmApp.ajaxUtils.handleAjaxError
    })

    $('#form-builder-submit-btn').on('click', () => { this.addFormSyntaxToInput() })
  }

  addFormSyntaxToInput() {
    const formName = this.$formSelect.val()
    const linkName = $('#choose-link').val()
    if (formName) {
      let result = `<%+Form, ${formName}`
      if (linkName) {
        result += `, ${linkName}`
      }
      result += ' %>'
      this.parent.insertInEditor(result)
    } else {
      this.parent.insertInEditor('Woops')
    }
    this.$modal.modal('hide')
  }
}
