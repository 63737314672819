import LocationVariableModal from './modal-builder-location-variable'
import FormLinkModal from './modal-builder-form-link'
import FormFieldModal from './modal-builder-form-field'
import ComponentsModal from './modal-builder-components'

export default class LocationVariableWysiwygPlugin {
  editor

  constructor(editor) {
    this.editor = editor

    this.addToolbarHelpButton()
    this.addToolbarCalmInsert()
  }

  addToolbarHelpButton() {
    const $textileHelp = $('#letters-syntax-help')
    if ($textileHelp.length > 0) {
      const url = $textileHelp.data('url')
      this.editor.addButton('syntax_help', {
        icon: 'help',
        tooltip: $textileHelp.data('label'),
        onclick() { window.open(url, '_blank').focus() }
      })
    }
  }

  addToolbarCalmInsert() {
    const calmMenu = []

    // Insert LocationVariable
    if ($('#location-variable-builder').length > 0) {
      const locVarModal = new LocationVariableModal(this)
      calmMenu.push({
        text: $('#location-variable-builder').data('label'),
        onclick() { locVarModal.open() }
      })
    }

    // Insert Link to a Form
    if ($('#form-link-builder').length > 0) {
      const formLinkModal = new FormLinkModal(this)
      calmMenu.push({
        text: $('#form-link-builder').data('label'),
        onclick() { formLinkModal.open() }
      })
    }

    // Insert System Variable
    if (gon.available_system_variables) {
      calmMenu.push({
        text: gon.available_system_variables_label,
        menu: Object.entries(gon.available_system_variables).map(([group, variables]) => ({
          text: group,
          menu: variables.map((variable) => ({
            text: variable,
            onclick: () => { this.insertInEditor(`<%=${variable}%>`, true) }
          }))
        }))
      })
    }

    // Insert Special Link/Button
    if (gon.available_components) {
      const componentsModal = new ComponentsModal(this)
      calmMenu.push({
        text: gon.available_components_label,
        menu: Object.entries(gon.available_components).map(([group, components]) => ({
          text: group,
          menu: components.map((component) => ({
            text: component,
            onclick: () => { componentsModal.open(component) }
          }))
        }))
      })
    }

    // Form fields
    if (gon.available_form_fields) {
      const formFieldModal = new FormFieldModal(this)
      calmMenu.push({
        text: gon.available_form_fields_label,
        menu: [{
          // Text Input
          text: gon.available_form_fields.reply,
          onclick: () => { formFieldModal.open('reply', gon.available_form_fields.reply) }
        }, {
          // Choice (radio buttons)
          text: gon.available_form_fields.question.label,
          menu: Object.entries(gon.available_form_fields.question.options).map(([id, label]) => ({
            text: label,
            onclick: () => { formFieldModal.open(id, label) }
          }))
        }, {
          // Action (radio buttons)
          text: gon.available_form_fields.action.label,
          menu: Object.entries(gon.available_form_fields.action.options).map(([id, label]) => ({
            text: label,
            onclick: () => { formFieldModal.open(id, label) }
          }))
        }]
      })
    }

    // Conditionally Display
    if (gon.available_if_conditions) {
      calmMenu.push({
        text: gon.available_if_conditions.label,
        menu: gon.available_if_conditions.options.map((option) => ({
          text: option.label,
          onclick: () => {
            this.insertInEditor(`<%+If, ${option.id} %>${gon.available_if_conditions.placeholder}<%+endif%>`)
          }
        }))
      })
    }

    if (calmMenu.length > 0) {
      this.editor.addButton('calm_insert', {
        type: 'menubutton',
        text: t('lookups.location_variable.builder.label'),
        icon: 'pluscircle',
        menu: calmMenu
      })
    }
  }

  insertInEditor(val, inline = false) {
    if (!inline) val = `<br/>${val}<br/>`
    this.editor.insertContent(val)
  }
}
