<template>
  <div class="app-actions btn-floating-sm">
    <!-- If a context app is selected -->
    <template v-if="selectedAppContext.type == 'app'">
      <!-- Contact Us -->
      <a
        class="btn btn-secondary"
        @click="$emit('contact', selectedAppContext.courseApplication.id)"
      >
        <i class="pi pi-envelope me-2" />
        {{ $t('comm.transmissions.index.contact') }}
      </a>
      <!-- Cancel App -->
      <!-- <a class="btn btn-outline-danger"
        v-if="cancellableAppContexts.includes(selectedAppContext)"
        :href="cancelUrl(selectedAppContext)">
        {{ $t('comm.lookups.action_radio_buttons.cancel') }}
      </a> -->
    </template>

    <!-- If no context app is selected, display dropdown to choose context -->
    <template v-if="!selectedAppContext.type || selectedAppContext.type == 'all'">
      <!-- Contact Us -->
      <div v-if="appContexts.length > 0" class="btn-group">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
          <i class="pi pi-envelope me-2" />
          {{ $t('comm.transmissions.index.contact') }}
        </button>
        <ul class="dropdown-menu">
          <li v-for="appContext in appContexts" :key="appContext.courseApplication.id">
            <a class="dropdown-item" @click="$emit('contact', appContext.courseApplication.id)">
              {{ appContext.label }}
            </a>
          </li>
        </ul>
      </div>
      <!-- Cancel App -->
      <!-- <div class="btn-group" v-if="cancellableAppContexts.length > 0">
        <button
          class="btn btn-outline-danger dropdown-toggle" type="button" data-bs-toggle="dropdown">
          {{ $t('comm.lookups.action_radio_buttons.cancel') }}
        </button>
        <ul class="dropdown-menu">
          <li v-for="appContext in cancellableAppContexts">
            <a class="dropdown-item" :href="cancelUrl(appContext)">
              {{ appContext.label }}
            </a>
          </li>
        </ul>
      </div> -->
    </template>
  </div>
</template>

<script>
export default {
  props: {
    contextOptions: {
      type: Array,
      required: true
    },
    selectedContext: {
      type: Object,
      required: true
    }
  },
  emits: ['contact'],
  computed: {
    selectedAppContext() {
      if (this.appContexts.length == 1) return this.appContexts[0]
      return this.selectedContext
    },
    appContexts() {
      return this.contextOptions.filter((opt) => opt.type == 'app')
    },
    cancellableAppContexts() {
      return this.appContexts.filter(
        (appContext) => appContext.courseApplication.event_start_date > new Date()
               && !['Cancelled', 'Discontinued', 'NewNoVacancy', 'NoVacancy', 'Refused']
                 .includes(appContext.courseApplication.conf_state)
      )
    }
  },
  methods: {
    cancelUrl(context) {
      return this.$routes.mvip_mvip_cancel_application_path({ id: context.courseApplication.id })
    }
  }
}
</script>

<style lang='scss' scoped>
  // @include media-breakpoint-up(md) {
  //   .app-status {
  //     float: right;
  //     display: flex;
  //     height: 2.5rem;
  //     flex-direction: row;
  //     align-items: center;
  //   }
  // }
</style>
