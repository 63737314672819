import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import initAllJsAfterContentLoaded from 'helpers/core/init-all-js-after-content-loaded'

export function registerRemoteModalDirective(app) {
  app.directive('remote-modal', {
    mounted(el) {
      new RemoteModalLink(el)
    }
  })
}

export function initRemoteModals() {
  $('[v-remote-modal]:not([data-loaded])').each(function() { new RemoteModalLink(this) })
}

class RemoteModalLink {
  $link = null

  $modal = null

  useCache

  constructor(element) {
    this.$link = $(element)
    if (this.$link.data('loaded')) return
    this.useCache = this.$link.data('cached')
    this.$link.click((e) => this.handleClick(e))
    this.$link.attr('data-loaded', true)
  }

  // When link is clicked, we make a request to get the content from the href url
  handleClick(event) {
    event.preventDefault()
    event.stopPropagation()
    if (this.$link.attr('disabled')) return // manually return cause the $link might not be a button or a link, and so disabled attr si not handled natively
    if (this.$link.data('confirm')) return // needed for confirms refs #5243 - combine this with attr data-confirm_remote_modal="true"

    // If a minimized modal exist, we open it instead of loading the new modal refs #4875
    const $minimizedModal = $('.modal.minimized')
    if ($minimizedModal.length) {
      $minimizedModal.find('.maximize-button').trigger('click')
      return
    }

    const $existingModal = $(`#${this.$link.data('modal-id')}`)
    if ($existingModal.length && this.useCache) {
      // Modal already loaded, we just oepn it again
      $existingModal.modal()
    } else {
      // Load modal dialog from server
      $existingModal.remove()
      $('body').css('padding-right', 0) // bootstrap modal adjust padding so simulate the scroll bar
      this.$link.attr('disabled', true)

      const url = this.$link.attr('href') || this.$link.attr('data-href')
      axios.get(url, { params: { layout: 'layouts/modal' } })
        .then(({ data }) => { this.initModalFormHtmlResponse(data) })
        .catch((error) => handleAjaxError(error, this))
        .finally(() => { this.$link.attr('disabled', false) })
    }
  }

  // Use the response of the ajax request to create a new modal
  initModalFormHtmlResponse(data) {
    this.$modal = $(data)
    // Empty previous modal otherwise we have some time initialisation problems
    // For example for new_internal.js, it loads vue into el: #new_internal
    // But opening a second modal, the vue loads it the first #new_internal created
    // (on previous modal) so new modal does not work
    $('#dynamic-modals-container').empty().append(this.$modal)
    if (!this.$modal.attr('id')) {
      const newId = `${Date.now()}-${Math.floor(Math.random() * 10000)}`
      this.$modal.attr('id', `remote-modal-${newId}`)
    }

    this.$link.attr('disabled', false).data('modal-id', this.$modal.attr('id'))

    initAllJsAfterContentLoaded(`#${this.$modal.attr('id')}`)

    setTimeout(() => {
      // Wait for Vue being mounted to initialize other stuff

      this.initMinimizeButton()
      this.initRemoteForm()

      // Init select2 inside modal
      this.$modal.find('select').on('select2:open', (e) => {
        $('.select2-container--open').css('z-index', '100000')
      })
      // bind any cancel buttons to also close the modal using the small X top right
      $('.close-v-model-modal').on('click', function() {
        $(this).closest('.modal-content').find('button.close').trigger('click')
      })

      // Hide already visible modal and display new modal
      $('.modal:visible').modal('hide')
      $('.modal-backdrop').remove()
      this.$modal.modal()
    }, 0) // Timeout to let Vue initialize the DOM
  }

  // If the modal contain a Form, we initialize to work with ajax remote submission
  initRemoteForm() {
    if (this.$modal.find('form').length == 0) return
    const $form = this.$modal.find('form').attr('data-remote', true).attr('data-type', 'json')
    this.initCalmSubmitButtons()

    $form.on('ajax:success', (settings, data, xhr) => {
      this.reEnableCalmSubmitButtons()
      this.$modal.modal('hide')
      if (data.removed_from_inbox_id) { // refs #4962
        const $el = $('#people-letters-datatable').find(`[data-msg-id='${data.removed_from_inbox_id.toString()}']`)
        $el.closest('tr').removeClass('inbox')
        $el.find('.label-default-light').remove() // removes the inbox reason label
      }
      // TODO REF INBOX the method called on ajax success should be configurable somehow inside the link or the modal
      if (data.message) {
        calmAlertBootstrap({ headerText: 'Success', messageText: data.message })
        // refs #4872 after sending a message to another app, we still wants to reload letters in order
        // to reflect the changes on the inbox field
        if (window.course_applications_form) window.course_applications_form.updateLettersDatatable(true)
        if (window.jano_people_show_edit) window.jano_people_show_edit.updateLettersDatatable(true)
      } else if (window.course_applications_form) {
        $('[href=#letters]').trigger('click') // open letters tab in case we sent a message from elsewhere (rsl at approves for example)
        window.course_applications_form.updateAppState(data)
        window.course_applications_form.updateLettersDatatable(true)
      } else if (window.events_communication) {
        window.events_communication.updateLettersDatatable(true)
      } else if (window.jano_people_show_edit) {
        $('[href=#letters]').trigger('click') // open letters tab in case we sent a message from elsewhere (contact assignor)
        window.jano_people_show_edit.updateLettersDatatable(true)
      } else {
        calmAlertBootstrap({ headerText: 'Success', messageText: 'Success' })
      }
    })
    $form.on('ajax:error', (data, status, xhr) => {
      handlingError()
      this.reEnableCalmSubmitButtons()
      let errorMsg = ''
      if (status.status == 422) { // Unprocessable Entity, validation failed
        const errors = JSON.parse(status.responseText)
        if (typeof (errors) === 'string') {
          errorMsg = errors
        } else {
          for (const i in errors) errorMsg += `<li>${i}: ${errors[i]}</li>`
        }
      } else {
        errorMsg = `${status.statusText}: ${status.responseText.substring(0, 800)}`
      }
      this.$modal.find('.modal-errors').html(`<div class='alert alert-danger'>${errorMsg}</div>`)
    })
  }

  initCalmSubmitButtons() {
    this.$modal.on('click', '[type=submit][data-calm-disable-with]', function() {
      const $btn = $(this)
      $btn.attr('data-original-value', $btn.val())
      $btn.val($btn.data('calm-disable-with'))
      // Need to wait the form to be submitted before disabling the button
      setTimeout(() => { $btn.prop('disabled', true) }, 0)
    })
  }

  reEnableCalmSubmitButtons() {
    const $btn = this.$modal.find('[data-calm-disable-with]:disabled')
    $btn.val($btn.attr('data-original-value'))
    $btn.prop('disabled', false)
  }

  initMinimizeButton() {
    $('.minimize-button, .maximize-button').on('click', function() {
      $('.modal-backdrop').toggle()
      $(this).closest('.modal').toggleClass('minimized')
    })
  }
}
