import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import initAllJsAfterContentLoaded from 'helpers/core/init-all-js-after-content-loaded'

// Example: Tab
// %a.remote-link{ data: { toggle: "tab", url: "/url", href: "async-target" } } Click to the tab
// #async-target

// Example: Link
// %a.remote-link{ data: { url: "/url", target: "async-target" } } Click to load async content
// #async-target

// Example: Aysnc load content into itself
// .remote-content{ data: { url: "/url" } }
// .remote-content{ data: { url: "/url", spinner: "false" } }

// Example: Aysnc load a row below the current row
// %a.remote-togglable-row-link{ data: { url: "/url" } }

export default function initRemoteLinks() {
  $('.remote-link:not([data-loaded])').on('click', function() {
    if (this.dataset.loaded) return

    this.dataset.loaded = true
    let target
    if (this.dataset.toggle == 'tab') {
      target = document.querySelector((new URL(this.href)).hash)
    } else {
      target = document.getElementById(this.dataset.target)
    }
    loadContentTo(this, target)
  })

  $('.remote-content:not([data-loaded])').each(function() {
    this.dataset.loaded = true
    loadContentTo(this, this)
  })

  $('.remote-togglable-row-link:not([data-initialized])').each(function() {
    this.dataset.initialized = true
    const $btn = $(this)
    $btn.addClass('btn btn-sm btn-default')
    $btn.html('<i class="fa fa-eye p-0"></i>')

    $(this).on('click', function() {
      const $row = $(this).closest('tr')

      if (!this.dataset.loaded) {
        this.dataset.loaded = true

        // Adds new tr to contains the remote content
        const columnsLength = $row.find('td:visible').length
        const $extraRow = $(`<tr role="row" class="remote-togglable-row" style="display: none;">
                               <td colspan="${columnsLength}" class="no-row-click"></td>
                             </tr>`)
        $extraRow.insertAfter($row)
        loadContentTo(this, $extraRow.find('td')[0])
      }
      $row.next('.remote-togglable-row').toggle()
      $btn.find('i').toggleClass('fa-eye').toggleClass('fa-eye-slash')
    })
  })
}

function loadContentTo(element, target) {
  const { url, spinner } = element.dataset
  const $target = $(target)
  if (spinner != 'false') {
    $target.html('<div class="calm-spinner-page-placeholder"><div class="calm-spinner"></div></div>')
  }
  axios.get(url, { params: { layout: 'layouts/remote' } })
    .then(({ data }) => {
      $target.html(data)
      return initContentLoaded($target)
    })
    .catch((error) => handleAjaxError(error, this))
}

function initContentLoaded($target) {
  const $remoteContainer = $target.find('.remote-container')
  // Add unique id
  const newId = `${Date.now()}-${Math.floor(Math.random() * 10000)}`
  $remoteContainer.attr('id', `remote-container-${newId}`)
  initAllJsAfterContentLoaded(`#${$remoteContainer.attr('id')}`)
}
