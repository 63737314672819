<template>
  <div class="checkbox-segment">
    <strong v-if="action == 'show'" class="text-success">
      <input
        type="checkbox"
        class="form-check-input"
        :checked="segment.value"
        :disabled="segment.value"
      >
      <span class="checkbox-segment-value">
        {{ displaySegmentValue(segment.value) }}
      </span>
    </strong>
    <template v-else>
      <input
        :id="segment.ident"
        type="checkbox"
        class="form-check-input"
        :name="`form[segments][${segment.ident}]`"
        :value="modelValue"
        :disabled="action == 'preview'"
        :required="!!segment.required"
        @input="$emit('update:modelValue', $event.target.checked)"
      >
    </template>
  </div>
</template>

<script>
export default {
  props: {
    segment: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: ''
    },
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  methods: {
    displaySegmentValue(segValue) {
      if (segValue == 'true') return this.$t('comm.lookups.general.yes')
      return this.$t('comm.lookups.general.no')
    }
  }
}
</script>

<style lang='scss' scoped>
  .checkbox-segment {
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
  .checkbox-segment-value {
    padding-left: 10px;
  }
</style>
