import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import RailsRoutesPlugin from 'helpers/core/rails-routes-plugin'
import LocalizePlugin from 'helpers/core/localize-plugin'
import { RailsPermissionsPlugin, railsPermissionsDirective } from 'helpers/core/rails-permissions'
import MediaQueryMixin from 'helpers/core/media-query-mixin'
import ToastService from 'primevue/toastservice'
import PrimeVue from 'primevue/config'
import Toast from 'primevue/toast'
import Tooltip from 'primevue/tooltip'
import { registerRemoteModalDirective } from 'components/remote/remote-modal-link'
import { registerInitModelDirective } from 'helpers/core/v-init-models'

window.Routes = require('routes.js.erb')

export function createVueApp(config, containerSelector) {
  // prevents runtime errors as gon is not defined yet
  const translations = (typeof window.gon !== 'undefined' && window.gon.i18n) || {}

  const i18n = createI18n({
    legacy: true, // legacy = options api
    globalInjection: true,
    locale: 'en', // we make vue think it's always en, but actually the translations given for 'en' will be in the user locale, see set_frontend_translations
    messages: { en: translations }
  })

  const app = createApp(config)

  app.use(i18n)
  app.use(RailsRoutesPlugin)
  app.use(RailsPermissionsPlugin)
  app.use(MediaQueryMixin)
  app.use(LocalizePlugin)
  app.use(PrimeVue)
  app.use(ToastService)

  app.directive('can', railsPermissionsDirective)
  app.directive('tooltip', Tooltip)
  registerRemoteModalDirective(app)
  registerInitModelDirective(app)

  app.component('Toast', Toast)

  // Depending on which part on the application we are (registration, myvipassana...)
  // we register different base components
  if (window.registerGlobalVueComponents) {
    window.registerGlobalVueComponents(app)
  }

  app.mixin({
    data() {
      return { locale: undefined }
    },
    mounted() {
      this.locale = gon.locale
    },
    methods: {
      openLocalePicker() {
        this.$refs.localePicker.toggle()
      }
    },
    watch: {
      locale(newLocale, oldLocale) {
        if (!newLocale || !oldLocale) return
        const url = new URL(window.location.href)
        url.searchParams.set('save_interface_lang', 'true')
        Turbo.visit(url.toString().replace(`/${oldLocale}/`, `/${newLocale}/`))
      }
    }
  })

  if (containerSelector) {
    // return the root component so we can call methods on it
    // exemple : window.course_applications_form.updateLettersDatatable(true)
    return app.mount(containerSelector)
  }

  return app
}

export const app = createVueApp()

export default class {
  constructor(options, selector = '#bigcontainer') {
    if (!options.el && selector) {
      options.el = selector
    }
    return createVueApp(options, selector)
  }
}
