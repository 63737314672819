<template>
  <div class="choice-segment">
    <strong v-if="action == 'show'" class="text-success">
      {{ displaySegmentChoice(segment.value) }}
    </strong>
    <template v-else>
      <div
        v-for="choice in segmentChoices()"
        :key="segment.ident + choice"
        class="form-check"
      >
        <input
          :id="segment.ident + choice"
          class="form-check-input"
          :type="segment.multiple ? 'checkbox' : 'radio'"
          :name="`form[segments][${segment.ident}][]`"
          :value="choice"
          :disabled="action == 'preview'"
          :required="!!segment.required"
          @input="$emit('update:modelValue', choice)"
        >
        <label class="form-check-label" :for="segment.ident + choice">
          {{ displaySegmentChoice(choice) }}
        </label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    segment: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, Array, Object],
      default: ''
    }
  },
  emits: ['update:modelValue'],
  methods: {
    segmentChoices() {
      if (this.segment.class_name == 'ActionSegment') return this.segment.actions
      return this.segment.choices
    },
    displaySegmentChoice(option) {
      if (this.segment.translations) {
        const index = this.segmentChoices().indexOf(option)
        if (this.segment.translations[index]) return this.segment.translations[index]
      }

      if (this.segment.class_name == 'ActionSegment') {
        return this.$t(`comm.lookups.action_radio_buttons.${option}`)
      }
      if (option == 'yes') return this.$t('comm.lookups.general.yes')
      if (option == 'no') return this.$t('comm.lookups.general.no')

      // If no translation, make it human readable
      return this.capitalizeSplit(option)
    },
    capitalizeSplit(word) {
      const formattedWord = word ? word.replace(/_/g, ' ') : ''
      return formattedWord.charAt(0).toUpperCase() + formattedWord.slice(1)
    }
  }
}
</script>

<style lang='scss' scoped>
  .choice-segment {
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
</style>
