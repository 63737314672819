// Imrove bootstrap tabs
// - Open a tab depending on the URL hash
// - Auto open the first tab if no hash supplied
// - Scroll on top when changing tab

// Note, you can listen for tab being open with shown.bs.tab event
export default function initTabs() {
  $('.nav-tabs:not([data-initialized])').each(function() {
    this.dataset.initialized = true
    $(document).on('click', 'a[data-toggle=tab]', function() {
      window.location.hash = $(this).attr('href')
      if (!$(this).hasClass('auto-clicked-from-child-tab')) checkAllNavTabsHaveOneChildSelected()
      $(window).scrollTop(0)
      setTimeout(() => $(window).scrollTop(0), 0)
    })

    let { hash } = window.location
    if (!hash && gon) hash = `#${gon.tab_to_open}`
    openHash(hash)

    checkAllNavTabsHaveOneChildSelected()

    // Handle validation in multiple tabs
    $(document).on('click', 'form :submit', checkMultiTabsFormValidation)
  })
}

function openHash(hash) {
  const tab = $(`.nav-tabs a[href="${hash}"]`)
  if (tab.length == 0) return

  // For nested tabs (like whiteboards) open also the parent tab
  const parentTab = tab.closest('.tab-pane')
  if (parentTab.length > 0) {
    const link = getLinkAssociatedToTabPane(parentTab)
    link.addClass('auto-clicked-from-child-tab')
    link.trigger('click')
  }

  // Use hash to open first tab
  tab.trigger('click')
}

function getLinkAssociatedToTabPane(tabPane) {
  return $(`.nav-tabs a[href="#${tabPane.attr('id')}"]`)
}

function checkAllNavTabsHaveOneChildSelected() {
  // Do this for each nav tabs in case multiple (like for whiteboards)
  $('.nav-tabs:visible').each(function() {
    if ($(this).find('li.active').length == 0) {
      $(this).find('li:first-child:visible a').trigger('click')
    }
  })
}

// If a required field is not set in one of the tab, when clicking submit
// It will open this tab so the user understand the problem
// #4404
function checkMultiTabsFormValidation() {
  const $invalid = $(this).closest('form').find(':invalid')
  if (!$invalid.filter(':visible').length) {
    const $invalidHidden = $invalid.filter(':hidden').first()
    if ($invalidHidden.length) {
      const $tabPaneHidden = $invalidHidden.closest('.tab-pane:hidden')
      getLinkAssociatedToTabPane($tabPaneHidden).trigger('click')
    }
  }
}
