export default class {
  constructor(parent) {
    this.parent = parent
    this.initializeDom()
  }

  initializeDom() {
    const that = this
    this.$modal.find('.remove-bad-chars').off('input').on('input', function() {
      $(this).val(that.removeBadChars($(this).val()))
    })
    this.$modal.find('.remove-commas').off('input').on('input', function() {
      $(this).val($(this).val().replace(/,|"/g, ' '))
    })
    $('.add-radio-option-btn').off('click').on('click', () => { this.addRadioOption() })
  }

  get $modal() {
    return $('#form-field-modal')
  }

  input(name) {
    return this.$modal.find(`#form_field_${name}`)
  }

  inputVal(name) {
    const input = this.input(name)
    return input.attr('type') == 'checkbox' ? input.prop('checked') : input.val()
  }

  inputGroup(name) {
    return this.input(name).closest('.form-group')
  }

  onModalSubmit(callback) {
    this.$modal.find('.submit-btn').off('click').on('click', () => {
      this.input('id').closest('.form-group').toggleClass('has-error', !this.inputVal('id'))
      if (this.inputVal('id')) {
        callback()
        this.$modal.modal('hide')
      }
    })
  }

  open(action, title = '') {
    this.$modal.find('.modal-title').html(title)
    switch (action) {
      case 'yes_no': this.openYesNoBuilder(); break
      case 'reconfirm_cancel': this.addCancelReconfirmBtn(); break
      case 'wl_reconfirm_cancel': this.addWlCancelReconfirmBtn(); break
      case 'cancel': this.addCancelBtn(); break
      case 'custom': this.openChoiceBuilder(); break
      case 'reply': this.openReplyBuilder(); break
      default: break
    }
  }

  addCancelReconfirmBtn() {
    this.parent.insertInEditor('<%+Action, cancel|reconfirm, required="true" %>')
  }

  addWlCancelReconfirmBtn() {
    this.parent.insertInEditor('<%+Action, cancel|waitlist_reconfirm, required="true" %>')
  }

  addCancelBtn() {
    this.parent.insertInEditor('<%+Action, cancel %>')
  }

  reinitModal() {
    this.$modal.find('.field-options .form-group').hide()
    this.$modal.find('.has-error').removeClass('has-error')
    this.$modal.find('[name]').val('')
    this.$modal.find('[type=checkbox]').attr('checked', false)
    this.$modal.find('.field-options').show()
  }

  showModal() {
    this.$modal.modal('show')
  }

  openReplyBuilder() {
    this.reinitModal()
    this.input('rows').val(4)
    this.inputGroup('rows').show()
    this.showModal()

    this.onModalSubmit(() => {
      const options = this.optionsToString({ rows: this.inputVal('rows') })
      this.parent.insertInEditor(`<%+Reply, ${this.inputVal('id')}${options} %>`)
    })
  }

  openYesNoBuilder() {
    this.reinitModal()
    this.$modal.find('.field-options').hide()
    this.showModal()

    this.onModalSubmit(() => {
      const options = this.optionsToString()
      this.parent.insertInEditor(`<%+Choice, ${this.inputVal('id')}, yes|no${options} %>`)
    })
  }

  openChoiceBuilder() {
    this.reinitModal()
    this.$modal.find('.question-options').show().find('.inputs').html('')
    this.addRadioOption()
    this.inputGroup('multiple').show()
    this.showModal()

    this.onModalSubmit(() => {
      const values = this.getArrayValueFromSelector('[name="values[]"]:visible')
      const options = this.optionsToString({
        multiple: this.inputVal('multiple') ? 'true' : null,
        translations: this.getArrayValueFromSelector('[name="labels[]"]:visible')
      })
      this.parent.insertInEditor(`<%+Choice, ${this.inputVal('id')}, ${values}${options} %>`)
    })
  }

  getArrayValueFromSelector(selector) {
    return this.$modal.find(selector).toArray().map((e) => e.value).filter((v) => v)
      .join(' | ')
  }

  getBasicOptions() {
    const options = {}
    if (this.inputVal('required')) options.required = 'true'
    if (this.inputVal('custom_field')) options.save_in_app = 'true'
    return options
  }

  optionsToString(additionalOptions) {
    const options = { ...this.getBasicOptions(), ...additionalOptions }
    if (Object.values(options).length == 0) return ''

    let result = ', '
    Object.entries(options).forEach(([key, value]) => {
      if (value !== null) result += `${key}=${JSON.stringify(value)} `
    })
    return result
  }

  addRadioOption() {
    const template = this.$modal.find('.question-options .template').clone(true).removeClass('template hidden')
    this.$modal.find('.question-options .inputs').append(template)
  }

  // prevent a number of invalid chars that cause problems when set as a custom_field name
  // in the course app form refs #4026 + possibly prevent other problems
  removeBadChars(string) {
    return string.replace(/\s+/, '_')
      .replace(/[^\w\d_]/g, '')
      .replace(/_{2,}/, '_')
      .replace(/^_/, '')
      .replace(/,_/, ',')
  }
}
