<template>
  <div v-show="activated" class="columns-picker gap-2 float-end">
    <!-- Layouts List -->
    <LayoutPicker
      v-if="layouts.length > 0"
      :layouts="layouts"
      :selected-layout="selectedLayout"
      @click-destroy="doDestroy"
      @layout-selected="loadLayout"
    />

    <!-- Save New Layout Button -->
    <CalmButton
      v-if="!selectedLayout.id || selectedLayoutChanged"
      v-tooltip.top="$t('datatable.common.save_layout')"
      class="btn-success"
      icon="pi pi-save"
      @click="openSavePanel"
    />

    <!-- Columns picker -->
    <CalmButton
      v-tooltip.top="$t('datatable.common.columns')"
      class="btn btn-dark"
      icon="fas fa-cog"
      @click="toggleColumnsPanel"
    />

    <!-- Save Dialog -->
    <OverlayPanel ref="savePanel">
      <div class="d-flex align-items-center p-3">
        <template v-if="selectedLayout.editable">
          <!-- Update existing -->
          <CalmButton
            class="btn-primary"
            icon="fas fa-sync-alt"
            :loading="loadingUpdate"
            :label="$t('datatable.common.update', { name: selectedLayout.name })"
            @click="doUpdate"
          />
          <span class="mx-3">{{ $t('comm.lookups.general.or') }}</span>
        </template>
        <!-- Create new layout -->
        <div class="input-group flex-grow-1">
          <input
            ref="layoutNameInput"
            v-model="layoutName"
            class="form-control"
            type="text"
            :placeholder="$t('datatable.common.layout_name')"
            @keyup.enter="doCreate"
          >
          <div class="input-group-btn">
            <CalmButton
              class="btn-success"
              :loading="loadingCreate"
              :disabled="!layoutName"
              :label="$t('datatable.common.create_layout')"
              @click="doCreate"
            />
          </div>
        </div>
      </div>
    </OverlayPanel>

    <OverlayPanel ref="columnsPanel" :show-close-icon="false" :dismissable="true">
      <!-- Columns List -->
      <div class="columns-container">
        <div
          v-for="(columns, group) in groupedTogglableColumns"
          :key="group"
          class="columns-group"
          :style="group == 'undefined' ? 'order: 100' : ''"
        >
          <div v-if="Object.keys(groupedTogglableColumns).length > 0" class="group-title">
            {{ group == 'undefined' ? $t('datatable.common.other_group') : group }}
          </div>
          <div v-for="column in columns" :key="column.field" class="checkbox">
            <input
              :id="column.field"
              v-model="column.selected"
              type="checkbox"
              @change="onColumnChanged()"
            >
            <label v-tooltip.right="column.headerTitle || {disabled: true}" :for="column.field">
              {{ column.header }}
              <span v-show="column.headerTitle" class="ms-1 asterix">(?) </span>
            </label>
          </div>
        </div>
      </div>

      <!-- Header -->
      <div class="overlay-footer d-flex justify-content-end">
        <!-- Uncheck All -->
        <div class="btn border-0 me-2" @click="uncheckAllColumns">
          {{ $t('datatable.common.uncheck_columns') }}
        </div>
        <!-- Close Button -->
        <CalmButton
          class="btn-dark"
          :loading="loadingColumns"
          :label="$t('datatable.common.done')"
          @click="$refs.columnsPanel.hide($event)"
        />
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel'
import CrudMixin from 'mvip/helpers/crud-mixin'
import CalmButton from 'components/common/CalmButton'
import LayoutPicker from './LayoutPicker.vue'

export default {
  components: { OverlayPanel, LayoutPicker, CalmButton },
  mixins: [CrudMixin],
  props: {
    activated: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default() { return [] }
    },
    fieldsToHide: {
      type: Array,
      default() { return [] }
    },
    columnsToDisplay: {
      type: Array,
      default() { return [] }
    },
    tableKey: {
      type: String,
      required: true
    }
  },
  emits: ['update:columns-to-display'],
  data() {
    return {
      selectedLayout: {},
      selectedLayoutChanged: false,
      layouts: [],
      layoutName: '',
      clickEvent: {},
      loadingColumns: false
    }
  },
  created() {
    this.layouts = gon.datatable.layouts
    let layoutToLoad = JSON.parse(window.localStorage.getItem(this.storageKey))
    if (layoutToLoad && layoutToLoad.id) {
      layoutToLoad = { ...this.originalLayout(layoutToLoad.id), ...layoutToLoad }
    }
    layoutToLoad ||= this.layouts[0]
    // display all columns by default
    layoutToLoad ||= { columns: this.togglableColumns.map((col) => col.field) }
    this.loadLayout(layoutToLoad)
    this.detectLayoutChange()
    this.updateColumnsToDisplay()
    // init crud mixin
    this.crud.layouts = {
      route: 'datatable_layout',
      params: {},
      label: this.$t('datatable.common.layout')
    }
  },
  computed: {
    togglableColumns() {
      return this.columns.filter((col) => col.togglable && !col.hidden
      && !this.fieldsToHide.includes(col.field))
    },
    groupedTogglableColumns() {
      return this.togglableColumns.reduce((result, col) => {
        (result[col.group] = result[col.group] || []).push(col)
        return result
      }, {})
    },
    storageKey() {
      return `${this.tableKey}_layout`
    }
  },
  methods: {
    toggleColumnsPanel(event) {
      this.clickEvent = event
      this.$refs.columnsPanel.toggle(event)
      this.$forceUpdate() // fix some reactivity issue for column.selected checkbox
    },
    uncheckAllColumns() {
      this.columns.forEach((col) => { col.selected = false })
      this.$forceUpdate() // fix some reactivity issue for column.selected checkbox
      this.onColumnChanged()
    },
    selectedFields() {
      return this.columns.filter((col) => col.selected).map((col) => col.field)
    },
    onColumnChanged() {
      this.loadingColumns = true
      // Wait for loading button to be drawn
      setTimeout(() => {
        this.updateColumnsToDisplay()
        window.localStorage.setItem(this.storageKey, JSON.stringify({
          id: this.selectedLayout.id,
          columns: this.selectedFields()
        }))
        this.detectLayoutChange()
      }, 0)
    },
    updateColumnsToDisplay() {
      // In order to preserve columns orders, we need to
      // force redraw by reseting the columns to display
      const cols = this.columns.filter((col) => {
        if (this.activated && this.togglableColumns.includes(col)) return col.selected
        return !this.fieldsToHide.includes(col.field)
      })
      this.$emit('update:columns-to-display', [])
      this.$nextTick(() => {
        this.$emit('update:columns-to-display', cols)
        this.loadingColumns = false
      })
    },
    originalLayout(id = this.selectedLayout.id) {
      if (!id) return {}
      return this.layouts.find((layout) => layout && layout.id == id) || {}
    },
    loadLayout(layout) {
      this.selectedLayout = layout
      this.selectedLayoutChanged = false
      this.columns.forEach((col) => {
        col.selected = this.selectedLayout.columns.includes(col.field)
      })
      this.onColumnChanged()
    },
    detectLayoutChange() {
      if (!this.selectedLayout.id) return
      this.selectedLayoutChanged = !this.arrayEquals(
        this.originalLayout().columns,
        this.selectedFields()
      )
    },
    arrayEquals(a, b) {
      const sortedB = b.sort()
      return Array.isArray(a)
      && Array.isArray(sortedB) && a.length === sortedB.length
      && a.sort().every((val, index) => val === sortedB[index])
    },
    openSavePanel(event) {
      this.layoutName = ''
      this.$refs.savePanel.show(event)
      this.$nextTick(function() {
        this.$refs.layoutNameInput.focus()
      })
    },
    doCreate() {
      this.create('layouts', {
        table_key: this.tableKey,
        name: this.layoutName,
        editable: true,
        columns: this.selectedFields()
      }, (result) => {
        this.loadLayout(result.data)
        this.$refs.savePanel.hide()
      })
    },
    doUpdate() {
      this.update(
        'layouts',
        { ...this.selectedLayout, ...{ columns: this.selectedFields() } },
        (result) => {
          this.loadLayout(result.data)
          this.$refs.savePanel.hide()
        }
      )
    },
    doDestroy(layout) {
      this.destroy('layouts', layout, () => {
        if (this.selectedLayout.id == layout.id) {
          this.selectedLayout = {}
          this.selectedLayoutChanged = false
        }
      })
    }
  },
  watch: {
    fieldsToHide(newVal, oldVal) {
      if (!this.arrayEquals(newVal, oldVal)) this.updateColumnsToDisplay()
    }
  }
}
</script>

<style lang='scss' scoped>
  .columns-picker {
    display: flex;
  }
  .p-overlaypanel {
    top: initial !important;
    left: initial !important;
    right: 0 !important;
    margin-top: 3rem;
    transform-origin: top !important;

    a, .btn { margin-bottom: 0 } // cancel the rule made on datatable-header

    &:before, &:after {
      content: none;
    }
    .p-overlaypanel-content {
      padding: 0;
    }
    .overlay-footer {
      padding: .8rem 1rem;
      background-color: #f6f6f6;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
    .columns-container {
      display: flex;
      column-gap: 2rem;
      padding: 1.25rem;
      padding-bottom: .7rem;
      .group-title {
        font-weight: bold;
      }
    }
  }

  .checkbox {
    label {
      font-weight: normal;
    }
    input:checked + label {
      color: var(--bs-primary);
    }
    .asterix {
      font-size: .8rem;
      opacity: .8;
    }
  }
</style>
