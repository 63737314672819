<template>
  <Dialog v-model:visible="displayAppForm" :header="title" :modal="true">
    <form v-if="editingApp" class="needs-validation" novalidate>
      <!-- Event Type -->
      <div class="mb-3">
        <calm-select
          v-model="editingApp.event_type"
          :options="eventTypeList()"
          :placeholder="$t('comm.course_applications.attrs.event_type')"
          :max-height="250"
        />
      </div>
      <!-- Date -->
      <div class="mb-3 input-group">
        <span class="input-group-text">{{ $t('datetime.prompts.year') }}</span>
        <calm-select v-model="editingApp.year" :options="yearList()" :max-height="200" />
        <span class="input-group-text">{{ $t('datetime.prompts.month') }}</span>
        <calm-select v-model="editingApp.month" :options="monthList()" :max-height="200" />
      </div>
      <!-- Location -->
      <div class="mb-3">
        <input
          v-model="editingApp.venue"
          class="form-control"
          required
          :placeholder="$t('comm.course_applications.attrs.location')"
        >
      </div>
      <!-- Participation -->
      <div v-if="screen.isSM" class="mb-3">
        <calm-select
          v-model="editingApp.participation"
          :options="participationList()"
          label="label"
          track-by="value"
        />
      </div>
      <div v-else class="pt-1 mb-3 d-flex justify-content-evenly">
        <div
          v-for="participation in participationList()"
          :key="participation.value"
          class="form-check form-check-inline"
        >
          <input
            :id="participation.value"
            v-model="editingApp.participation"
            class="form-check-input"
            :value="participation.value"
            type="radio"
            required
          >
          <label class="form-check-label" :for="participation.value">
            {{ participation.label }}
          </label>
        </div>
      </div>
      <!-- PartTime -->
      <div v-if="isPartTime" class="mb-3 input-group">
        <input
          v-model="editingApp.part_time_duration"
          class="form-control"
          type="number"
          :placeholder="$t('comm.course_applications.attrs.partime.how_many_days')"
        >
        <div class="input-group-text">
          {{ $t('comm.course_applications.attrs.partime.days') }}
        </div>
      </div>
      <!-- Teacher -->
      <div class="mb-3 input-group">
        <span class="input-group-text">{{ $t('comm.course_applications.attrs.teacher') }}</span>
        <calm-select
          v-model="editingApp.teacher"
          :options="teacherResults"
          label="name"
          :max-height="200"
          open-direction="top"
          :loading="teacherLoading"
          :clearable="true"
          @search-change="searchTeacher"
        />
      </div>
      <!-- Note -->
      <div class="mb-3">
        <input
          v-model="editingApp.note"
          class="form-control"
          type="text"
          :placeholder="$t('comm.course_applications.attrs.note')"
        >
      </div>
    </form>
    <template v-if="editingApp" #footer>
      <button class="btn btn-default me-3" @click="displayAppForm = false">
        {{ $t('lookups.actions.cancel') }}
      </button>
      <button class="btn btn-primary" :disabled="!appIsValid" @click="submitApp">
        <span v-if="editingApp.id">{{ $t('comm.lookups.actions.save') }}</span>
        <span v-else>{{ $t('comm.lookups.actions.add') }}</span>
      </button>
    </template>
  </Dialog>
</template>

<script>
import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import Dialog from 'primevue/dialog'

export default {
  components: { Dialog },
  data() {
    return {
      displayAppForm: false,
      editingApp: null,
      teacherResults: [],
      teacherLoading: false
    }
  },
  computed: {
    title() {
      if (!this.editingApp) return ''
      return this.editingApp.id
        ? this.$t('comm.course_applications.headings.edit_course_title')
        : this.$t('comm.course_applications.headings.add_course_title')
    },
    appIsValid() {
      return this.editingApp.year && this.editingApp.participation
        && (!this.isPartTime || this.editingApp.part_time_duration)
        && this.editingApp.venue && this.editingApp.event_type
    },
    isPartTime() {
      return ['old_student_pt', 'server_pt'].includes(this.editingApp.participation)
    }
  },
  methods: {
    yearList() {
      const years = []
      for (let i = 1969; i <= new Date().getFullYear(); i += 1) years.push(i)
      return years
    },
    monthList() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
        (i) => [(new Date(2000, i)).toLocaleDateString(this.locale, { month: 'long' }), i]
      )
    },
    participationList() {
      return ['old_student_ft', 'old_student_pt', 'server_ft', 'server_pt'].map(
        (participation) => ({
          label: this.$t(`comm.course_applications.attrs.participation_values.${participation}`),
          value: participation
        })
      )
    },
    eventTypeList() {
      return this.$root.eventTypesForSelect
    },
    searchTeacher(query) {
      if (!query || query.length < 2) return
      this.teacherLoading = true
      axios.get(this.$routes.at_search_path({ format: 'json' }), {
        params: {
          term: query,
          include_ex_at: true,
          include_non_calm_at: true,
          limit: 30
        }
      }).then((result) => {
        this.teacherResults = result.data.results // .map(obj => obj.name)
        this.teacherLoading = false
      }).catch((error) => handleAjaxError(error, this))
    },
    editApp(app) {
      this.editingApp = { ...app } // clone app
      if (this.editingApp.start_date) {
        this.editingApp.month = this.editingApp.start_date.getMonth()
        this.editingApp.year = this.editingApp.start_date.getFullYear()
      }
      this.displayAppForm = true
    },
    submitApp() {
      this.displayAppForm = false
      if (this.editingApp.year) {
        this.editingApp.start_date = new Date(
          this.editingApp.year,
          this.editingApp.month || null,
          10
        ) // init to day 10, so we don't have issue with time zone. cause January 1st 00:00 at GMT -5 = December 31 at GMT 0
      }

      if (!this.editingApp.id) {
        this.$root.create('apps', this.editingApp)
      } else {
        this.$root.update('apps', this.editingApp)
      }
    }
  }
}
</script>
