<template>
  <Dialog v-model:visible="displayAppForm" :header="title" :modal="true">
    <form v-if="editingApp" class="needs-validation" novalidate>
      <!-- Event Type -->
      <div class="mb-3">
        <calm-select
          v-model="editingApp.event_type"
          :options="eventTypeList()"
          :placeholder="$t('comm.course_applications.attrs.event_type')"
          :max-height="200"
        />
      </div>

      <!-- Participation -->
      <div v-if="screen.isSM" class="mb-3">
        <calm-select
          v-model="editingApp.participation"
          :options="participationList()"
          label="label"
          track-by="value"
        />
      </div>
      <div v-else class="pt-1 mb-3 d-flex">
        <div
          v-for="participation in participationList()"
          :key="participation.value"
          class="form-check form-check-inline"
        >
          <input
            :id="participation.value"
            v-model="editingApp.participation"
            class="form-check-input"
            :value="participation.value"
            type="radio"
            required
          >
          <label class="form-check-label" :for="participation.value">
            {{ participation.label }}
          </label>
        </div>
      </div>

      <!-- Count -->
      <div class="mb-3">
        <div class="input-group">
          <div class="input-group-text">
            {{ $t('comm.course_applications.attrs.batch_number_of_courses') }}
          </div>
          <input v-model="editingApp.batch_count" class="form-control" type="number">
        </div>
      </div>

      <!-- Note -->
      <div class="mb-3">
        <textarea
          v-model="editingApp.note"
          class="form-control"
          type="text"
          rows="4"
          :placeholder="$t('comm.course_applications.attrs.note')"
        />
      </div>
    </form>

    <template v-if="editingApp" #footer>
      <button class="btn btn-default me-3" @click="displayAppForm = false">
        {{ $t('lookups.actions.cancel') }}
      </button>
      <button class="btn btn-primary" :disabled="!appIsValid" @click="submitApp">
        <template v-if="editingApp.id">
          {{ $t('comm.lookups.actions.save') }}
        </template>
        <template v-else>
          {{ $t('comm.lookups.actions.add') }}
        </template>
      </button>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
  components: { Dialog },
  data() {
    return {
      displayAppForm: false,
      editingApp: null,
      teacherResults: [],
      teacherLoading: false
    }
  },
  computed: {
    title() {
      if (!this.editingApp) return ''
      return this.editingApp.id
        ? this.$t('comm.course_applications.headings.edit_course_in_batch_title')
        : this.$t('comm.course_applications.actions.add_course_in_batch')
    },
    appIsValid() {
      return this.editingApp.batch_count
      && this.editingApp.participation
      && this.editingApp.event_type
    }
  },
  methods: {
    participationList() {
      return ['old_student_ft', 'server_ft'].map((participation) => ({
        label: this.$t(`comm.course_applications.attrs.participation_values.${participation}`),
        value: participation
      }))
    },
    eventTypeList() {
      return this.$root.eventTypesForSelect
    },
    editApp(app) {
      this.editingApp = { ...app } // clone app
      this.displayAppForm = true
    },
    submitApp() {
      this.displayAppForm = false

      // TODO
      if (!this.editingApp.id) {
        this.$root.create('apps', this.editingApp)
      } else {
        this.$root.update('apps', this.editingApp)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  form {
    min-height: 230px; // enough space for event type picker
  }
</style>
