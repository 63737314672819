import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

export default {
  components: { DataTable, Column },
  props: ['data'],
  data() {
    return { perPage: 10, expandedRows: {} }
  },
  computed: {
    // DataTable html attributes
    tableBindings() {
      return {
        value: this.data,
        paginator: this.data && this.data.length > this.perPage,
        rows: this.perPage,
        dataKey: 'id',
        class: 'hoverable',
        expandedRows: this.expandedRows,
        rowClass: (item) => (this.expandedRows[item.id] ? 'expanded' : '')
      }
    },
    tableListeners() {
      return {
        'row-expand': (event) => {
          this.onToggleExpand(event.data)
        },
        'row-click': (event) => {
          // Small hack, when expander icon is clicked, the row-click event is also
          // fired. In this case we need to let PrimeVue expanded do its own job
          if (event.originalEvent.srcElement.classList.contains('p-row-toggler-icon')) {
            return
          }
          const row = event.data
          if (this.expandedRows[row.id]) this.expandedRows = []
          else this.expandedRows = { [row.id]: true }
          this.onToggleExpand(row)
        },
        'update:expandedRows': (value) => {
          this.expandedRows = value
        }
      }
    }
  },
  methods: {
    onToggleExpand(row) {
      // Implement in Component if needed
    }
  }
}
