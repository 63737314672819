function permittedTo(action, context) {
  return gon.permissions[context] && gon.permissions[context].includes(action)
}

// make rails permissions available from javascript
// this plugin adds a $permittedTo method on any Vue instance
export const RailsPermissionsPlugin = {
  install(app, options) {
    app.config.globalProperties.$can = permittedTo
  }
}

// Directive %div(v-can:edit:messages) === %div(v-show="$can('edit', 'messages')")
export function railsPermissionsDirective(el, binding, vnode) {
  const args = binding.arg.split(':')
  if (args.length != 2) {
    console.error('v-can directive needs two arguments sperated by column. Exple -> v-can:edit:messages')
    return
  }

  if (!permittedTo(args[0], args[1])) {
    const comment = document.createComment(' ')
    Object.defineProperty(comment, 'setAttribute', { value: () => undefined })

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el)
    }

    // Update the vnode properties without accessing .data
    if (vnode.el) {
      vnode.el = comment
    }
    vnode.text = ' '
    vnode.isComment = true
  }
}
