<template>
  <Dropdown
    v-bind="$attrs"
    ref="dropdown"
    :options="rooms"
    option-value="id"
    option-label="name"
    data-key="id"
    :filter="true"
    :show-clear="true"
    append-to="body"
  >
    <template #value="{value}">
      <RoomDisplay :id="value" :app="app" :apps="apps" />
    </template>
    <template #option="{option}">
      <RoomDisplay :id="option.id" :app="app" :apps="apps" :inside-select="true" />
    </template>
  </Dropdown>
</template>

<script>
// !! To use this component, the root component (i.e. page component) should use rooms-mixin !!
import Dropdown from 'primevue/dropdown'
import { compareStringsWithNumbers } from 'helpers/string_helper'

export default {
  components: { Dropdown },
  props: {
    app: {
      type: Object,
      required: true
    },
    apps: {
      type: Array,
      required: true
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    if (!this.$root.rooms) {
      console.error('CALM WARNING : root component (i.e. page componen) should use RoomMixin')
    }
  },
  mounted() {
    this.$root.fetchRooms()
    if (this.autofocus) setTimeout(() => { this.$refs.dropdown.show() }, 0)
  },
  computed: {
    rooms() {
      return this.$root.rooms.map((room) => ({
        ...room,
        ...{
          suitable: this.$root.suitable(room, this.app),
          full: this.$root.appsUsing(room.id, this.apps).length >= room.beds
        }
      })).sort((a, b) => {
        if (a.suitable == b.suitable) {
          if (a.full == b.full) return compareStringsWithNumbers(a.name, b.name)
          return a.full ? 1 : -1
        }
        return b.suitable ? 1 : -1
      })
    }
  }
}
</script>
