<template>
  <div class="room-container">
    <i v-if="$root.fetchingRooms" class="fas fa-circle-notch fa-spin" />
    <div
      v-if="room"
      class="room"
      :title="message"
      :class="{ unsuitable, overallocated: displayOverallocated, full: displayFull }"
    >
      {{ room.name }}
      <i v-if="room.suits_disabled" class="ms-2 fab fa-accessible-icon" />
      <i v-if="room.ensuite" class="ms-2 fas fa-shower" />
      <i v-if="room.own_accommodation" class="ms-2 fas fa-campground" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      default: null,
      validator: (value) => typeof value === 'number' || typeof value === 'string'
    },
    app: {
      type: Object,
      required: true
    },
    apps: {
      type: Array,
      required: true
    },
    rooms: {
      type: Array,
      default: null
    },
    insideSelect: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    if (!this.$root.rooms) {
      console.error('CALM WARNING : root component (i.e. page componen) should use RoomsMixin')
    }
  },
  mounted() {
    this.$root.fetchRooms()
  },
  computed: {
    roomId() {
      return this.id || this.app.room_id
    },
    allRooms() {
      return this.rooms || this.$root.rooms
    },
    room() {
      return this.allRooms.find((r) => r.id == this.roomId)
    },
    unsuitable() {
      return !this.$root.suitable(this.room, this.app)
    },
    appsUsingThisRoom() {
      return this.$root.appsUsing(this.roomId, this.apps).length
    },
    overallocated() {
      return this.appsUsingThisRoom > this.room.beds
    },
    displayOverallocated() {
      return this.overallocated && !this.insideSelect
    },
    full() {
      return this.appsUsingThisRoom >= this.room.beds
    },
    displayFull() {
      return this.full && this.insideSelect
    },
    message() {
      const messages = []
      if (this.unsuitable) messages.push(this.$t('lookups.room.warning.room_not_suitable'))
      if (this.displayFull) messages.push(this.$t('lookups.room.warning.full'))
      if (this.displayOverallocated) {
        messages.push(`${
          this.$t('lookups.room.warning.overallocated')} ${this.appsUsingThisRoom}
          /${this.room.beds}`)
      }
      messages.push(this.room.info)
      return messages.join(' | ')
    }
  }
}
</script>

<style lang='scss' scoped>
  .room {
    display: flex;
    align-items: center;
    &.unsuitable {
      color: #ad850e;
    }
    &.overallocated {
      color: var(--bs-danger);
    }
    &.full {
      opacity: .5;
    }
    i {
      font-size: .9rem;
      opacity: .8;
    }
  }
</style>
