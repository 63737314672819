<template>
  <DataTable
    v-bind="tableBindings"
    sort-field="transaction_date"
    :sort-order="-1"
    responsive-layout="scroll"
    v-on="tableListeners"
  >
    <Column field="transaction_date">
      <template #body="slotProps">
        {{ slotProps.data.transaction_date.toLocaleDateString(locale, { dateStyle: "long" }) }}
      </template>
    </Column>
    <Column field="location" />
    <Column field="amount" />
    <Column :expander="true" body-class="expander" />

    <!-- Content -->
    <template #expansion="slotProps">
      <template v-if="slotProps.data.receipt">
        <div
          class="btn btn-print rounded-circle pi pi-print fs-2 float-end"
          @click="printReceipt()"
        />
        <div class="full-page-print">
          {{ slotProps.data.receipt }}
        </div>
      </template>
      <CalmSpinner v-else />
    </template>
  </DataTable>
</template>

<script>
import DatatableExpandableMixin from 'mvip/helpers/datatable-expandable-mixin'
import { axios, handleAjaxError } from 'helpers/core/ajax-utils'

export default {
  mixins: [DatatableExpandableMixin],
  methods: {
    onToggleExpand(dana) {
      if (!dana.receipt) {
        axios.get(this.$routes.mvip_show_dana_receipt_path(dana.id, 'json'))
          .then((result) => dana.receipt = result.data)
          .catch((error) => handleAjaxError(error, this))
      }
    },
    printReceipt() {
      window.print()
    }
  }
}
</script>

<style lang='scss' scoped>
  :deep(.p-datatable-thead) {
    display: none;
  }
  .btn-print {
    padding: .75rem;
    background-color: var(--bs-gray-200);
    line-height: 1;
  }
</style>
