<template>
  <div class="btn-group layout-picker">
    <button
      class="btn btn-default dropdown-toggle text-capitalize"
      type="button"
      data-toggle="dropdown"
    >
      <span v-if="selectedLayout">
        <i class="me-1">{{ $t('datatable.common.layout') }}</i>
        {{ selectedLayout.name }}
      </span>

      <template v-else>
        {{ $t('datatable.common.layouts') }}
      </template>
      <span class="caret" />
    </button>

    <ul class="dropdown-menu dropdown-menu-right">
      <li class="dropdown-header">
        {{ $t('datatable.common.default_layouts') }}
      </li>
      <li
        v-for="layout in nonEditableLayouts"
        :key="layout.id"
        :class="{active: layout.id == selectedLayout.id}"
        @click="$emit('layout-selected', layout)"
      >
        <a>{{ layout.name }}</a>
      </li>

      <template v-if="editableLayouts.length > 0">
        <li class="divider" role="separator" />
        <li class="dropdown-header">
          {{ $t('datatable.common.user_layouts') }}
        </li>
        <li
          v-for="layout in editableLayouts"
          :key="layout.id"
          :class="{active: layout.id == selectedLayout.id}"
          @click="$emit('layout-selected', layout)"
        >
          <a class="d-flex">
            <span class="flex-grow-1">{{ layout.name }}</span>
            <span class="text-end" style="width: 2rem">
              <ConfirmButton
                btn-class="ms-2 p-0"
                type="danger"
                :message="$t('datatable.common.delete_confirm', { name: layout.name })"
                @confirmed="$emit('click-destroy', layout)"
              >
                <i
                  v-tooltip.right="$t('datatable.common.delete')"
                  class="fa fa-trash destroy-layout"
                />
              </ConfirmButton>
            </span>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import ConfirmButton from 'mvip/components/common/ConfirmButton'

export default {
  components: { ConfirmButton },
  props: {
    layouts: {
      type: Array,
      required: true
    },
    selectedLayout: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  emits: ['layout-selected', 'click-destroy'],
  computed: {
    nonEditableLayouts() {
      return (this.layouts || []).filter((l) => !l.editable)
    },
    editableLayouts() {
      return (this.layouts || []).filter((l) => l.editable)
    }
  }
}
</script>

<style lang='scss'>
  .layout-picker {
    .destroy-layout {
      display: none;
      float: right;
      color: var(--bs-danger) !important;
      opacity: .5;
      &:hover {
        opacity: 1;
      }
    }
    li:hover .destroy-layout {
      display: inline;
    }
    .dropdown-toggle span {
      i {
        font-style: normal;
        opacity: .7;
      }
    }
  }
</style>
