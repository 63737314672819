<template>
  <div class="person-language row">
    <div class="input-group mb-2">
      <div
        class="btn bg-white d-flex border btn-sm pi pi-angle-down"
        @click="$emit('move-down', personLanguage)"
      />
      <div
        class="btn bg-white d-flex border btn-sm pi pi-angle-up"
        @click="$emit('move-up', personLanguage)"
      />

      <calm-select
        v-if="personLanguage.isNew"
        :model-value="personLanguage.language_id"
        :options="assignableLanguages"
        @update:model-value="$emit('update:language', { ...personLanguage, language_id: $event })"
      />
      <calm-select
        v-else
        :model-value="personLanguage.language_id"
        :options="[language]"
        :disabled="true"
        class="disabled"
        @update:model-value="$emit('update:language', { ...personLanguage, language_id: $event })"
      />
      <calm-select
        :model-value="personLanguage.proficiency"
        :options="proficiencies"
        @update:model-value="$emit('update:proficiency',
                                   { ...personLanguage, proficiency: $event })"
      />
      <div
        class="btn-delete d-flex input-group-text btn pi pi-times bg-transparent"
        @click="$emit('delete', personLanguage)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    personLanguage: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    assignableLanguages: {
      type: Array,
      required: true
    },
    proficiencies: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    }
  },
  emits: ['delete', 'move-up', 'move-down', 'update:language', 'update:proficiency'],
  computed: {
    language() {
      return this.languages.find((obj) => obj[1] == this.personLanguage.language_id) || {}
    }
  }
}
</script>

<style lang='scss'>
.person-language {
  .calm-select-container.disabled {
    background-color: var(--bs-blue-150);
  }
  .multiselect--disabled {
    background-color: var(--bs-body-bg);
    opacity: 1;
  }
  .btn-delete {
    padding-right: 0.7rem !important;
  }
}
</style>
