import '@hotwired/turbo-rails'
// Global helpers loaded for every pages
import initIframeListener from 'helpers/core/iframe-communication'
import initPhoneInputs from 'components/common/phone-input'

// Base CALM components
import registerComponents from 'helpers/core/register-base-components'

import CalmSelect from 'components/common/CalmSelect'

// import pages script which will be automatically lodaded base on the page name
// Registration App and Mvip do not work the same, so we change the config here, used by module auto loader
import { importAll, autoLoadModuleFromRenderedViewName } from 'helpers/core/module-auto-loader'
import { initVModels } from 'helpers/core/v-init-models'

// Primevue styles
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/lara-light-blue/theme.css'

// Stylesheets
import 'mvip/myvipassana.scss'

// TODO mvip we are loading all the routes here, which is 160kb. We should do something like
// with translations : detect which route are being used in javascript and provide only those ones
window.Routes = require('routes.js.erb')

window.registerGlobalVueComponents = (app) => {
  registerComponents(app, require.context('mvip/components/common', true, /.*\.vue$/))
  app.component('CalmSelect', CalmSelect)
}

importAll(require.context('mvip/pages', true, /.*\.(ts|js|vue)$/))

document.addEventListener('turbo:load', initJavascripts)
// After form submission, turbo:load is not called, but turbo:render yes
document.addEventListener('turbo:render', initJavascripts)

function initJavascripts() {
  // We call this init on both turbo:render (for form submission)
  // and tubo:load for standard visits. Make sure to not init twice
  // otherwise VueJs raise error
  if (document.body.classList.contains('js-initialized')) return
  document.body.classList.add('js-initialized')

  initVModels()
  initIframeListener()

  autoLoadModuleFromRenderedViewName('.vue-container', true)

  const autoClickBtn = document.querySelector('.btn.auto-click')
  if (autoClickBtn) autoClickBtn.click()

  // other initialisation, after vue is initialized
  initPhoneInputs()
  const autofocus = document.querySelector('[autofocus]')
  if (autofocus) autofocus.focus()
}
