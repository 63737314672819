export default class {
  constructor(parent) {
    this.parent = parent
  }

  get $locVarSelect() {
    return $('#choose-loc-var')
  }

  get $modal() {
    return $('#choose-variable-dialog')
  }

  open() {
    if (this.$modal.hasClass('initialized')) {
      this.$locVarSelect.val('').trigger('change')
      this.$modal.modal('show')
      return
    }

    const url = $('#location-variable-builder').data('ajaxUrl')
    $.ajax({
      url,
      type: 'GET',
      dataType: 'json',
      success: (data) => {
        const options = [{ id: '', text: '' }].concat(data.loc_vars)
        this.$locVarSelect.select2({ data: options })
        this.$modal.modal('show').addClass('initialized')
      },
      error: calmApp.ajaxUtils.handleAjaxError
    })

    this.$locVarSelect.on('change', () => {
      const value = this.$locVarSelect.val()
      if (!value) return

      this.parent.insertInEditor(`<%=${value}%>`)
      this.$modal.modal('hide')
    })
  }
}
