export default class {
  constructor(parent) {
    this.parent = parent
  }

  initialize(component, $modal) {
    if ($modal.hasClass('initialized')) return

    $modal.find('.btn-submit').on('click', () => {
      const attrs = ['location', 'text', 'style', 'color', 'size']
      const options = {}
      attrs.forEach((att) => { options[att] = $modal.find(`[name$="[${att}]"]`).val() })
      if (component == 'publication_subscribe_btn' && !options.location) {
        $modal.find('[name$="[location]"]').closest('.form-group').addClass('has-error')
        return
      }

      const optionsValues = []
      Object.entries(options).forEach(([option, value]) => {
        if (value) optionsValues.push(`${option}="${value}"`)
      })
      let optionsString = optionsValues.join(' ')
      if (optionsString) optionsString = `, ${optionsString}`
      this.parent.insertInEditor(`<%=${component}${optionsString} %>`)

      $modal.modal('hide')
    })
    $modal.addClass('initialized')
  }

  open(component) {
    const $modal = $(`#choose-${component}-options-dialog`)
    if ($modal.length > 0) {
      this.initialize(component, $modal)
      $modal.modal('show')
    } else {
      this.parent.insertInEditor(`<%=${component}%>`)
    }
  }
}
