import { createVueApp } from 'helpers/core/vue-custom'

export default function() {
  setTimeout(() => {
    initAllSmsInputs()
  }, 0)

  $(document).on('init-sms-input', () => {
    initAllSmsInputs()
  })
}

function initAllSmsInputs() {
  document.querySelectorAll('.sms-input-vue-component:not(.initialized)').forEach((container) => {
    // Basic Vue component, we just want the sms-character-count component to be initialized
    // and connected to the content input
    // We create a new Vue app, because the letter translation use old javascript, and
    // loading vue on the whole page will probably cause issues and side effects
    createVueApp({ data: { content: undefined } }, container)

    container.classList.add('initialized')
  })
}
