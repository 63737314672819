<template>
  <DataTable
    v-bind="tableBindings"
    ref="table"
    v-model:filters="filters"
    edit-mode="cell"
    class="editable-cells-table"
    @row-click="handleRowClick"
    @cell-edit-complete="onCellEditComplete"
    @cell-edit-init="onCellEditInit"
    @cell-edit-cancel="onCellEditCancel"
  >
    <template #header>
      <div class="d-flex flex-wrap gap-2 mb-3">
        <div class="flex-grow-1 flex-shrink-0">
          <slot name="header" />
        </div>
        <button
          v-if="clearAllFiltersBtn && Object.values(filters).some(filter => filter.value)"
          class="btn btn-warning"
          type="button"
          @click="clearFilters()"
        >
          <i class="pi pi-filter-slash me-2" />
          {{ $t('datatable.common.clear_filters') }}
        </button>
        <ColumnsPicker
          v-model:columns-to-display="columnsToDisplay"
          :activated="columnsToggle"
          :columns="columns"
          :fields-to-hide="fieldsToHide"
          :table-key="tableKey"
        />
      </div>
    </template>

    <template #empty>
      <div class="text-center">
        {{ $t('datatable.common.no_result') }}
      </div>
    </template>

    <Column
      v-for="column of filteredColumns"
      v-bind="column"
      :key="column.field"
      header=""
    >
      <template #header>
        <span class="p-column-title" :title="column.headerTitle">{{ column.header }}</span>
      </template>

      <template #filter="{ filterModel, filterCallback }">
        <component
          :is="`${column.filter}-filter`"
          v-if="column.filter"
          :filter-model="filterModel"
          :filter-callback="filterCallback"
          :options="column.options || fieldsValues[column.field]"
        />
      </template>

      <template #body="{data,field}">
        <i v-if="data._loadingFields.includes(field)" class="fas fa-circle-notch fa-spin" />
        <template v-else>
          <RoomDisplay
            v-if="column.type == 'room'"
            :app="data"
            :apps="items"
          />
          <template v-else-if="data[field] instanceof Date">
            <span :title="column.dateFullOnHover ? $l(data[field], 'full') : null">
              {{ $l(data[field], column.dateFormat) }}
              <template v-if="column.appendField && data[column.appendField]">
                , {{ data[column.appendField] }}
              </template>
            </span>
          </template>
          <span v-else v-html="data[field]" />
        </template>
      </template>

      <template v-if="column.editable" #editor="{data}">
        <RoomInput
          v-if="column.type == 'room'"
          v-model="data[column.field]"
          class="flex-grow-1 align-items-center"
          autofocus
          :app="data"
          :apps="items"
        />
        <div
          v-else
          class="input-group btn-group d-flex flex-grow-1"
          style="margin: 0 -.5rem"
          @click.prevent.stop=""
        >
          <textarea
            v-model="data[column.field]"
            class="form-control p-2"
          />
        </div>
      </template>
    </Column>
    <slot />
  </DataTable>
</template>

<script>
import { get, post, handleAjaxError } from 'helpers/core/ajax-utils'
import { stripTags } from 'helpers/string_helper'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import RoomDisplay from 'components/rooms/RoomDisplay'
import RoomInput from 'components/rooms/RoomInput'
import ColumnsPicker from './ColumnsPicker.vue'

export default {
  components: { DataTable, Column, ColumnsPicker, InputText, RoomDisplay, RoomInput },
  events: {
    'row-clicked': "override primevue 'row-click' to take in account cell editing",
    'cell-after-edit': 'callback after the cell edit complete and ajax return successfully'
  },
  props: {
    route: {
      type: String,
      required: true
    },
    lazy: {
      type: Boolean,
      default: false
    },
    urlParams: {
      type: Object,
      default() { return {} }
    },
    columnsToggle: {
      type: Boolean,
      default: false
    },
    clearAllFiltersBtn: {
      type: Boolean,
      default: false
    },
    fieldsToHide: {
      type: Array,
      default() { return [] }
    },
    transformData: {
      type: Function,
      default(items) { return items }
    }
  },
  emits: ['row-clicked', 'cell-after-edit'],
  data() {
    return {
      tableKey: null,
      // filters
      filters: {},
      // columns
      columns: [],
      columnsToDisplay: [],
      fieldsValues: {},
      // Internal properties
      items: [],
      itemsRetrieved: {},
      totalRecords: 0,
      perPage: 10,
      first: 0,
      loading: false,
      pendingRequest: false,
      editingItemId: null,
      editLoading: []
    }
  },
  beforeMount() {
    if (gon.datatable) {
      this.tableKey = gon.datatable.key
      this.filters = Object.fromEntries(gon.datatable.columns.map((col) => [col.field, col]))
      this.columns = gon.datatable.columns.map((col) => {
        const newCol = { ...col }
        if (newCol.width) {
          newCol.headerClass = `w-${newCol.width}`
          newCol.bodyClass = `w-${newCol.width}`
          newCol.filterHeaderClass = `w-${newCol.width}`
          delete newCol.width
        }
        return newCol
      })
    }
    // Specify the umber of rows with prop
    if (this.$attrs.rows) this.perPage = this.$attrs.rows
  },
  computed: {
    filteredColumns() {
      return this.columnsToDisplay.filter((column) => !column.screen || this.screen[column.screen])
    },
    // Url params to get the data
    defaultUrlParams() {
      const result = { format: 'json' }
      if (this.lazy) {
        result.length = this.perPage
        result.start = this.first
      }
      return result
    },
    allUrlParams() {
      return { ...this.defaultUrlParams, ...this.urlParams }
    },
    stringifiedParams() {
      return JSON.stringify(this.allUrlParams)
    },
    // DataTable html attributes
    tableBindings() {
      return {
        ...{
          value: this.items,
          lazy: this.lazy,
          loading: this.loading,
          dataKey: 'id',
          // Filters
          // filters: this.filters,
          // Paginator
          paginator: this.totalRecords > this.perPage,
          rows: this.perPage,
          totalRecords: this.totalRecords,
          paginatorTemplate: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
          rowsPerPageOptions: [10, 25, 50, 150],
          currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords}',
          // Sort
          removableSort: true,
          // Others
          responsiveLayout: 'scroll',
          stateKey: this.tableKey,
          stateStorage: 'local',
          // Listeners
          page: (event) => { this.first = event.first },
          'update:filters': (event) => {
            Object.entries(event).forEach(([field, filter]) => {
              // If the filter is not in current columns list, just ignore.
              // It prevent situation where a filter is saved but
              // then the columns is not displayed anymore #5164
              if (!this.columns.find((c) => c.field === field)) return
              // Only update the value, otherwise the matchmode is saved inside
              // sessionStore and then we cannot override it by changing the
              // config in ruby datatable. If we want to let user choose the matchmode
              // and remember it, following line will need to be changed
              this.filters[field] = { ...this.filters[field], ...{ value: filter.value } }
            })
          },
          'state-restore': (event) => {
            if (this.$attrs.rows) return // rows is specified as a props, so keeping this value

            this.perPage = event.rows
            const options = this.tableBindings.rowsPerPageOptions
            if (!options.includes(this.perPage)) {
              [this.perPage] = options
            }
          }
        },
        ...this.$attrs
      }
    }
  },
  methods: {
    retrieveData() {
      if (this.loading) {
        this.pendingRequest = true
        return
      }
      this.pendingRequest = false
      this.items = []
      const requestParams = this.stringifiedParams
      if (this.itemsRetrieved[requestParams]) {
        // give time for the view to redraw with loading and no items
        this.handleResult(this.itemsRetrieved[requestParams])
      } else {
        this.loading = true
        const url = this.$routes[this.route](this.allUrlParams)
        get(url)
          .then((result) => {
            this.itemsRetrieved[requestParams] = result.data
            this.handleResult(result.data)
          }).catch((error) => handleAjaxError(error, this))
      }
    },
    handleResult(result) {
      this.items = result.data.map((data) => ({ ...data, ...{ _loadingFields: [] } }))
      this.items = this.transformData(this.items)
      this.totalRecords = result.recordsCount
      this.loading = false
      if (this.pendingRequest) {
        this.retrieveData()
      }
    },
    calculateFieldsValues() {
      // Group all unique value for each field.
      // this is used only for dropdown filter with auto options
      const fields = this.columns
        .filter((col) => col.filter == 'dropdown' && !col.options)
        .map((col) => col.field)

      fields.forEach((field) => {
        const values = this.items.map(
          (data) => stripTags(data[field])
        ).filter((v) => v && v !== 'null')

        this.fieldsValues[field] = [...new Set(values)]
      })
    },
    // filterBindings(column, slotProps) {
    //   return {
    //     filterModel: slotProps.filterModel,
    //     filterCallback: slotProps.filterCallback,
    //   }
    //   console.log("column config", column)
    //   console.log("slotProps", slotProps)
    //   col.filterModel = slotProps.filterModel
    //   col.filterCallback = slotProps.filterCallback
    //   col.options ||= this.fieldsValues[col.field]
    //   return col
    // },
    clearFilters() {
      Object.keys(this.filters).forEach((field) => {
        this.filters[field].value = null
      })
    },
    onCellEditComplete(event) {
      const { data, newValue, field } = event
      data._loadingFields.push(field)
      post(this.$routes.update_datatable_cell_path(), {
        class: this.tableKey,
        id: data.id,
        field,
        value: newValue
      }).then(() => {
        const oldValue = data[field]
        data[field] = newValue
        this.$emit('cell-after-edit', { data, field, newValue, oldValue })
        data._loadingFields = data._loadingFields.filter((f) => f != field)
      }).catch((error) => {
        handleAjaxError(error, this)
        data._loadingFields = data._loadingFields.filter((f) => f != field)
      })
      // if click to edit new cell, the complete event of the first cell arrive after
      // the init of the new cell, so we reset the editing only if it's the same id
      if (this.editingItemId == data.id) this.editingItemId = null
    },
    onCellEditCancel() {
      this.editingItemId = null
    },
    onCellEditInit(event) {
      // Prevent row click
      this.editingItemId = event.data.id
    },
    handleRowClick(event) {
      // Disable row click while editing
      if (!this.editingItemId) this.$emit('row-clicked', event)
    }
  },
  watch: {
    allUrlParams() {
      this.retrieveData()
    },
    items() {
      this.calculateFieldsValues()
    }
  }
}
</script>
