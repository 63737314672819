import { createVueApp } from 'helpers/core/vue-custom'

// Import all javascript files inside pages directory
const pageList = {}
export function importAll(requireContext) {
  requireContext.keys().forEach((fileName) => {
    // Transform ./people/form.js into people_form
    const componentName = fileName.replace('./', '').replace('/', '_').replace(/\.(ts|js|vue)$/, '')
    pageList[componentName] = requireContext(fileName).default
  })
}

export function autoLoadModuleFromRenderedViewName(containerSelector, loadVueOnAllPages = false) {
  // Automatically run page specific module based on controller and rendered view
  const container = document.querySelector(containerSelector)
  if (!container) {
    console.error('no element found with selector', containerSelector)
    return
  }

  const pageName = container.dataset.actionName
  try {
    if (typeof pageList[pageName] == 'object') {
      // Vue Configuration to create a component
      window[pageName] = createVueApp(pageList[pageName], containerSelector)
    } else {
      // Class to instanciate or function to call
      window[pageName] = new DynamicControllerActionClass(pageName)
    }
  } catch (error) {
    if (error.message.includes('is not a constructor')) {
      console.warn(`No javascript file has been defined for ${pageName}`)
    } else {
      console.error(`Error while loading ${pageName}`, error)
    }
    if (loadVueOnAllPages) return createVueApp({}, containerSelector)
  }
  return window[pageName]
}

// Instanciate a class/function base on the name contained in a variable
class DynamicControllerActionClass {
  constructor(className) {
    return new pageList[className]()
  }
}
