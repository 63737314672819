import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils'
import 'intl-tel-input/build/css/intlTelInput.css'
import './phone-input.css'

// Make it accesisble globally, it's used elsewhere in non webpack javascripts
window.intlTelInput = intlTelInput

export default function initAll() {
  document.querySelectorAll('.phone-input:not(.initialized)').forEach((domInput) => {
    init(domInput)
  })
}

function init(domInput) {
  domInput.classList.add('initialized')
  if (!domInput.closest('.form-group').querySelector('.error-msg')) {
    // supports multiple destroy/initialisation otherwise errors pile up
    const errorDiv = document.createElement('div')
    errorDiv.style.display = 'none'
    errorDiv.classList.add('error-msg')
    domInput.after(errorDiv)
  }
  const preferredCountries = (domInput.getAttribute('data-preferred-countries') || '').split(',')
  const telInputName = domInput.getAttribute('name')
  let hiddenInputName
  // add _international suffix
  if (telInputName.includes('[')) {
    hiddenInputName = `${telInputName.slice(0, -1)}_international]`
  } else {
    hiddenInputName = `${telInputName}_international`
  }
  const iti = window.intlTelInput(domInput, {
    initialCountry: preferredCountries[0],
    countryOrder: preferredCountries,
    hiddenInput() { return { phone: hiddenInputName } }
  })

  domInput.addEventListener('blur', () => {
    resetErrors(domInput)
    checkValidation(domInput, hiddenInputName)
  })
  domInput.addEventListener('change', () => { resetErrors(domInput) })
  domInput.addEventListener('keyup', () => { resetErrors(domInput) })

  // check validation on init
  checkValidation(domInput, hiddenInputName)
  return iti
}

function checkValidation(domInput, hiddenInputName) {
  const errorDiv = domInput.closest('.form-group').querySelector('.error-msg')
  const iti = window.intlTelInput.getInstance(domInput)
  if (domInput.value.trim() && !iti.isValidNumber()) {
    domInput.closest('.form-group').classList.add('has-error')
    const errorCode = iti.getValidationError()
    errorDiv.textContent = domInput.getAttribute(`data-error-${errorCode}`)
    errorDiv.style.display = 'block'
  } else {
    // update hiddenInput (intl-tel-input update this field on submit event,
    // but it's not working in CALM so manually updating it)
    document.querySelector(`[name="${hiddenInputName}"]`).value = iti.getNumber()
  }
}

function resetErrors(domInput) {
  const errorDiv = domInput.closest('.form-group').querySelector('.error-msg')
  domInput.closest('.form-group').classList.remove('has-error')
  errorDiv.textContent = ''
  errorDiv.style.display = 'none'
}
