<template>
  <div v-if="action != 'show'" class="attachment-segment d-flex mb-3 mt-n2">
    <div
      class="btn btn-outline-dark"
      :title="$t('comm.transmissions.form.attach_file')"
      @click="addFile()"
    >
      <i class="pi pi pi-paperclip py-1" />
    </div>
    <template v-for="file in files" :key="file.id">
      <div
        v-if="file.name"
        class="file-chosen bg-light border rounded d-inline-flex align-items-center py-2 px-3 ms-2"
      >
        <span class="file-name">{{ file.name }}</span>
        <i class="pi pi-times ms-2 fs-6" @click="removeFile(file)" />
      </div>
      <input
        :id="`file-${file.id}`"
        class="d-none"
        type="file"
        name="form[attachments][]"
        @change="file.name = getFileName($event)"
      >
    </template>
  </div>
</template>

<script>
export default {
  props: {
    segment: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      lastId: 0,
      files: []
    }
  },
  methods: {
    addFile() {
      const newFile = { id: this.lastId, name: '' }
      this.files.push(newFile)
      this.lastId += 1
      this.$nextTick(function() {
        // open file select windows
        this.$el.querySelector(`#file-${newFile.id}`).click()
      })
    },
    removeFile(file) {
      this.files.splice(this.files.indexOf(file), 1)
    },
    getFileName(event) {
      return event.target.value.split(/(\\|\/)/g).pop()
    }
  }
}
</script>

<style lang='scss' scoped>
  .file-chosen .pi-times:hover {
    cursor: pointer;
  }
</style>
