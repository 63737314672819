export default function initLayout() {
  // check if ol.breadcrumb is empty. If it is, we can show the subsection-tabs and hide breadcrumbs
  if ($('ol.breadcrumb').children().length == 0) {
    $('.subsection-tabs-container').show()
    $('ol.breadcrumb').hide()
  } else {
    $('#bigcontainer').css('padding-top', `${$('ol.breadcrumb').outerHeight() + 20}px`)
  }

  $('#common-alert-modal').modal({ show: false })

  // show which tab get errors
  $('.panel-with-tabs .error').each(function() {
    const id = `#${$(this).closest('.tab-pane').attr('id')}`
    const tab = $(`.nav a[href=${id}]`)
    if (!tab.hasClass('error')) tab.addClass('error').text(`${tab.text()} ⚠`)
    tab.click()
  })

  $('.scrollable-dropdown').css('max-height', `${window.innerHeight - 140}px`)
}
