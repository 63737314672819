<template>
  <div class="languages-container">
    <h2 class="mt-4 mb-3 d-flex align-items-center">
      <div class="me-auto">
        {{ $t("comm.people.form.headers.languages") }}
        <span class="subtitle">{{ $t("comm.people.form.headers.languages_subtitle") }}</span>
      </div>
      <div
        class="btn-add-language btn pi pi-plus rounded-circle bg-secondary text-white"
        @click="addLanguage()"
      />
    </h2>

    <PersonLanguage
      v-for="(personLanguage, index) in personLanguages"
      :key="personLanguage.id"
      :index="index"
      :languages="languages"
      :proficiencies="proficiencies"
      :person-language="personLanguage"
      :assignable-languages="assignableLanguages"
      @delete="removeLanguage($event)"
      @move-up="moveUpLanguage($event)"
      @move-down="moveDownLanguage($event)"
      @update:language="personLanguage.language_id = $event.language_id"
      @update:proficiency="personLanguage.proficiency = $event.proficiency"
    />

    <input type="hidden" name="person[languages]" :value="inputValue">
  </div>
</template>

<script>
import PersonLanguage from './PersonLanguage.vue'

export default {
  components: { PersonLanguage },
  data() {
    return {
      personLanguages: [],
      languages: [],
      proficiencies: [],
      createdLanguagesCount: 0
    }
  },
  mounted() {
    this.languages = gon.languages
    this.personLanguages = gon.person_languages
    this.proficiencies = gon.proficiencies.map(
      (prof) => [this.$t(`lookups.application_form.language_proficiency.${prof}`), prof]
    )
  },
  computed: {
    inputValue() {
      const result = this.personLanguages
        .filter((lang) => lang.language_id && lang.proficiency)
        .map((lang, index) => ({
          id: lang.isNew ? null : lang.id,
          language_id: lang.language_id,
          publication_preference: index + 1,
          proficiency: lang.proficiency
        }))
      return JSON.stringify(result)
    },
    assignedLanguageIds() {
      return this.personLanguages.filter((obj) => !obj.isNew).map((obj) => obj.language_id)
    },
    assignableLanguages() {
      return this.languages.filter((obj) => !this.assignedLanguageIds.includes(obj[1]))
    }
  },
  methods: {
    addLanguage() {
      this.personLanguages.push({
        id: `new_${this.createdLanguagesCount}`,
        isNew: true
      })
      this.createdLanguagesCount += 1
    },
    removeLanguage(lang) {
      this.personLanguages.splice(this.personLanguages.indexOf(lang), 1)
    },
    moveUpLanguage(lang) {
      const index = this.personLanguages.indexOf(lang)
      if (index > 0) {
        const temp = this.personLanguages[index - 1]
        this.personLanguages[index - 1] = lang
        this.personLanguages[index] = temp
      }
    },
    moveDownLanguage(lang) {
      const index = this.personLanguages.indexOf(lang)
      if (index < this.personLanguages.length - 1) {
        const temp = this.personLanguages[index + 1]
        this.personLanguages[index + 1] = lang
        this.personLanguages[index] = temp
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .subtitle {
    opacity: .8;
    font-size: 1rem;
    margin-left: 5px;
  }
  .btn-add-language {
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: bold;
  }
</style>
