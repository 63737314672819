const FORMATS = {
  date: { dateStyle: 'medium' },
  dateWithoutYear: { month: 'short', day: 'numeric' },
  time: { month: 'short', day: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit' },
  full: { dateStyle: 'full', timeStyle: 'full' }
}

export default {
  install(app, options) {
    app.config.globalProperties.$l = (date, format) => {
      if (!date) return ''
      if (typeof (date) == 'string') date = new Date(date)
      if (typeof (format) == 'string') format = FORMATS[format]
      format ||= FORMATS.date
      if (gon.time_zone) format.timeZone = gon.time_zone
      let { locale } = gon
      if (gon.locale_country_code) locale += `-${gon.locale_country_code}`
      return date.toLocaleString(locale, format)
    }
  }
}
