<template>
  <button class="btn" v-bind="$attrs" type="button" :disabled="loading">
    <i v-if="loading" class="fas fa-circle-notch fa-spin" :class="{'me-2': label}" />
    <i v-if="icon && !loading" :class="[icon, {'me-2': label}]" />
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
