export default (app, requireComponent) => {
  requireComponent.keys().forEach((fileName) => {
    // Get component config
    const componentConfig = requireComponent(fileName)
    // Get PascalCase name of component
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')
    // Register component globally
    app.component(componentName, componentConfig.default)
  })
}
