<template>
  <div class="segments-renderer">
    <template v-for="segment in internalSegments" :key="segment.ident">
      <template v-if="displaySegment(segment)">
        <component
          :is="componentName(segment)"
          v-model="values[segment.ident]"
          :segment="segment"
          :action="action"
        />
      </template>
    </template>
  </div>
</template>

<script>
import ChoiceSegment from './segments/ChoiceSegment.vue'
import ReplySegment from './segments/ReplySegment.vue'
import AttachmentSegment from './segments/AttachmentSegment.vue'
import TextSegment from './segments/TextSegment.vue'
import CheckboxSegment from './segments/CheckboxSegment.vue'

export default {
  components: {
    ChoiceSegment,
    ReplySegment,
    TextSegment,
    AttachmentSegment,
    CheckboxSegment
  },
  props: {
    segments: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      default: ''
    },
    isDoctor: Boolean,
    additionalQuestion: {
      type: String,
      default: ''
    },
    contextMessage: {
      type: String,
      default: ''
    },
    templateCode: {
      type: String,
      default: ''
    }
  },
  data() { return { values: {} } },
  computed: {
    internalSegments() {
      let result = this.segments

      // Mimick same logic than Form.add_context_and_additional_questions
      // TODO : remove this frontend logic and use API to get correct rendered value
      if (this.contextMessage) {
        result = [{ value: `<h3>${this.contextMessage}</h3>` }, ...result]
      }
      if (this.segments.some((s) => s.ident == 'custom_form_section_start')) {
        const section = { start: [], form: [], end: [] }
        let currentKey = 'start'
        result.forEach((segment) => {
          if (segment.ident == 'custom_form_section_start') currentKey = 'form'
          else if (segment.ident == 'custom_form_section_end') currentKey = 'end'
          else section[currentKey].push(segment)
        })
        if (section.form.length > 0) {
          result = section.start
          const sections = this.customFormSections || 'Health Issue 1'
          sections.split('\n').forEach((question) => {
            result.push({ value: `<h2>${question}</h2>` })
            result = result.concat(JSON.parse(JSON.stringify(section.form)))
          })
          result = result.concat(section.end)
        }
      }
      if (this.additionalQuestion) {
        result.push({ value: `<h2>${this.$t('comm.transmissions.form.additional_question')}</h2>` })
        this.additionalQuestion.split('\n').forEach((question, index) => {
          result.push({ value: `<p>${question}</p>` })
          result.push({
            class_name: 'ReplySegment',
            ident: `additional_question_${index}`,
            options: { required: true, rows: 3 }
          })
        })
      }

      this.segments.forEach((segment) => {
        if (segment.ident) this.values[segment.ident] = segment.value
      })

      return result
    }
  },
  methods: {
    // Handles the "show_when" value
    displaySegment(segment) {
      if (segment.class_name == 'SectionSegment') return false
      if (segment.doctor_only && this.isDoctor !== true) return false
      if (segment.value == '<br>') return false
      if (!segment.show_when) return true
      const conditions = segment.show_when.split('&&').map((c) => c.trim())
      return conditions.every((condition) => {
        const parts = condition.split('=')
        if (parts.length != 2) return true
        return this.values[parts[0]] == parts[1]
      })
    },
    componentName(segment) {
      if (segment.class_name == 'ActionSegment') return 'ChoiceSegment'
      return segment.class_name || 'TextSegment'
    }
  }
}

</script>

<style lang='scss'>
  // Customize display inside myCourses
  .segments-renderer {
    p {
      margin: 1em 0; // restore default p margins (was ovewritten by bootstrap)
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 1em;
    }

    h2 {
      font-size: 1.6rem;
      color: var(--bs-secondary);
    }
    h3 {
      font-size: 1.4rem;
    }
    h4 {
      font-size: 1.2rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
  // Customize display in CALM
  #calm-body .segments-renderer {
    --bs-secondary: var(--bs-primary);
  }
</style>
