// refs #4449 tinyMCE link button fails to focus in url field when in a bootstrap modal without this code
// so people cannot add urls in external or internal messages in course apps

// https://www.tiny.cloud/docs-4x/integrations/bootstrap/#usingtinymceinabootstrapdialog
// NOTE if we upgrade tinymce to version 5 we will need to change this to use the code at
// https://www.tiny.cloud/docs/integrations/bootstrap/#usingtinymceinabootstrapdialog

// Prevent Bootstrap dialog from blocking focusin
export default function() {
  $(document).on('focusin', (e) => {
    if ($(e.target).closest('.mce-window').length) {
      e.stopImmediatePropagation()
    }
  })
}
