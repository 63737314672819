<template>
  <Dialog v-model:visible="displayDialog" :header="$t('comm.transmissions.form.new_message_title')">
    <div class="message-form">
      <input
        v-model="message.subject"
        class="form-control mb-3 mt-1"
        type="text"
        :placeholder="$t('comm.transmissions.index.subject')"
        maxlength="50"
      >
      <textarea
        v-model="message.content"
        class="form-control mb-3 mt-1"
        rows="10"
        :placeholder="$t('comm.transmissions.index.message')"
      />
    </div>
    <template #footer>
      <div class="btn btn-default me-2" @click="displayDialog = false">
        {{ $t('lookups.actions.cancel') }}
      </div>
      <div v-show="displaySave" class="btn btn-outline-primary me-2" @click="submit(false)">
        {{ $t('comm.lookups.actions.save') }}
      </div>
      <div class="btn btn-primary" @click="submit(true)">
        {{ $t('comm.transmissions.form.send') }}
      </div>
    </template>
  </Dialog>
</template>

<script>
import { axios, handleAjaxError } from 'helpers/core/ajax-utils'
import Dialog from 'primevue/dialog'
import messagesTransformMixin from './messages-transform-mixin'

export default {
  components: { Dialog },
  mixins: [messagesTransformMixin],
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },
  emits: ['msg-created'],
  data() {
    return {
      message: {},
      displayDialog: false,
      displaySave: false
    }
  },
  methods: {
    open(msg = {}, opts = { displaySave: false }) {
      this.message = msg
      this.displayDialog = true
      this.displaySave = opts.displaySave
    },
    submit(deliver) {
      axios.post(
        this.$routes.mvip_student_create_message_path(),
        { message: this.message, deliver }
      )
        .then((result) => {
          let msg = this.transformMessage(result.data)
          const allMessages = this.$root.messages || this.messages || []
          msg = this.calculateActions(msg, allMessages.concat([msg]))
          this.$toast.add({
            severity: 'success',
            summary: this.$t(
              'comm.lookups.crud_messages.sent_success',
              { model: this.$t('comm.transmissions.index.message') }
            ),
            life: 3000
          })
          allMessages.push(msg)
          this.$emit('msg-created', msg)
          this.displayDialog = false
        }).catch((error) => handleAjaxError(error, this))
    }
  }
}
</script>

<style lang='scss'>
  .message-form {
    // simplify toolbar
    .ql-background, .ql-font, .ql-code-block {
      display: none !important;
    }
  }
</style>
