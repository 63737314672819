import LocationVariableWysiwygPlugin from 'components/letters/location-variable-wysiwyg-plugin'
import WysiwygUploadPlugin from 'components/common/wysiwyg-upload-plugin'
// module to handle the integration of calm standard customizations of the tinymce editor
export default function initCalmWysiwyg() {
  $('textarea[data-tinymce]').each(initEditor)

  $(document).on('init-wysiwyg', () => {
    initCalmWysiwyg()
  })
}

function initEditor() {
  // if a textarea with same id has been initialized in the past, we remove it refs #5939
  if (tinyMCE.get($(this).attr('id'))) tinyMCE.remove(`#${$(this).attr('id')}`)

  const config = $(this).data('tinymce')
  const theme = $(this).data('tinymce-theme') || 'no'
  const configName = $(this).data('tinymce-config-name') || 'no'

  config.setup = function(editor) {
    // init calm plugins
    new LocationVariableWysiwygPlugin(editor)
    new WysiwygUploadPlugin(editor)

    // Adds class for theme and config
    editor.on('init', function() {
      $(this.contentAreaContainer).closest('.mce-tinymce').addClass(`${theme}-theme ${configName}-config`)
    })

    editor.on('input', () => {
      // update related textarea on the fly
      $(editor.targetElm).val(editor.getContent()).trigger('change')
    })
  }

  // Timeout to fix #5939 #6073
  setTimeout(() => {
    tinyMCE.init(config)
  }, 100) // 0 is enough, settings to 100 just in case
}
