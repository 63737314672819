<template>
  <div class="reply-segment">
    <strong v-if="action == 'show'" class="text-success">
      {{ segment.value || $t("lookups.transmission.reply_segment.no_answer") }}
    </strong>
    <textarea
      v-else
      v-model="localValue"
      class="form-control"
      :name="`form[segments][${segment.ident}]`"
      :required="!!segment.required"
      :disabled="action == 'preview'"
      :rows="segment.rows"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    segment: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      default: 'show'
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  emits: ['update:modelValue'],
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .reply-segment {
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
</style>
