import { autoLoadModuleFromRenderedViewName } from 'helpers/core/module-auto-loader'
import { initVModels } from 'helpers/core/v-init-models'
import initLocationVarHelper from 'components/letters/location-variable-helper'
import initCustomFieldsQueryInput from 'components/ad_hoc_reports/init-custom-fields-query-input'
import initCalmWysiwyg from 'components/common/calm-wysiwyg'
import initSmsInputs from 'components/common/sms-input'
import initPhoneInputs from 'components/common/phone-input'
import initRemoteLinks from 'components/remote/remote-link'
import { initRemoteModals } from 'components/remote/remote-modal-link'
import initTabs from 'components/common/bootstrap-tabs'
import initIframeListener from 'helpers/core/iframe-communication'
import styleConfirmationPopupWithBootstrap from 'helpers/modals/bootstrap-confirmation-modal'
import tinymceModalFix from 'helpers/modals/tinymce-link-in-modal-fix'
import initLayout from 'helpers/core/layout'

export default function(containerSelector) {
  initAllJsAfterContentLoaded(containerSelector)

  // To use this method in old javascript code, we need a trick cause we cannot use import
  // so we use window event to communicate
  window.addEventListener('initAllJsAfterContentLoaded', (e) => {
    initAllJsAfterContentLoaded()
  })
}

// Initialize all JS, on page load, and also when content is asynchrnouly loaded
function initAllJsAfterContentLoaded(containerSelector) {
  // Adds setTimeout to try to resolve weird JS bug reported in #5962
  setTimeout(() => {
    initVModels()
    initIframeListener()

    if (containerSelector) autoLoadModuleFromRenderedViewName(containerSelector)

    initLayout()
    initLocationVarHelper() // As it's a cell and it can be included in any template, better always load this file
    initCustomFieldsQueryInput() // custom simple_form input
    initCalmWysiwyg()
    initSmsInputs()
    initPhoneInputs()
    initRemoteLinks()
    initRemoteModals()
    styleConfirmationPopupWithBootstrap()
    tinymceModalFix()

    initTabs() // at the end so listeners can be setup before show.bs.tab event fire

    const autoClickBtn = document.querySelector('.btn.auto-click')
    if (autoClickBtn) autoClickBtn.click()
  }, 0)
}
